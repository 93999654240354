import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import { PodClass } from '../../classes/Pod'
import Loading from '../Loading'
import LoadingPod from '../Pod/LoadingPod'
import Contents from '../Pod/Contents'

import Grid from '@mui/material/Unstable_Grid2'
import { Box, Tabs, Tab, createTheme, ThemeProvider } from '@mui/material'
import Notes from '../Pod/Notes'
import { useTranslation } from 'react-i18next'

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const theme = createTheme({
  palette: {
    secondary: {
      main: '#6c6cd3'
    }
  }
})


const Pod = () => {
  const { t } = useTranslation()
  const { podStore } = useStore()
  const pod: PodClass = podStore.pod
  const [tabIndex, setTabIndex] = useState("contents")

  if(pod === null) return <div>'pod' is null when trying to show pod</div>

  const handleTabChange = (_event: React.SyntheticEvent, newTabIndex: string) => {
    setTabIndex(newTabIndex)
  }

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    )
  }

  return (
    <>
      <Box sx={{ p: 5, overflowY: "auto" }}>
        <Grid sx={{ pl: 3 }} >
          <h2>
            {pod.name}
          </h2>
        </Grid>

        <ThemeProvider theme={theme}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="contents" label={t('Contents')} />
            <Tab value="notes" label={t('Notes')} />
          </Tabs>
          <TabPanel value={tabIndex} index="contents">
              <Contents
                pod={pod}
              />
          </TabPanel>
          <TabPanel value={tabIndex} index="notes">
            <Notes
              pod={pod}
            />
          </TabPanel>
        </ThemeProvider>
      </Box>
    </>
  )

}

export default observer(Pod)
