import React, {useState} from 'react'
import sessionStore from '../stores/sessionStore'
import LanguageSwitch from './LanguageSwitch'
import { useTranslation } from "react-i18next"
import Loading from './Loading'

const Login: React.FC = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const handleLogin = (() =>{
      setLoading(true)
      sessionStore.login()
    })

    if(loading) return <Loading />

    return (
      <>
        <div style={{justifySelf: "end", margin: "10px"}}>
          <LanguageSwitch />
        </div>
        <div style={{display: "Grid", gridTemplateRows: "min-content min-content", justifyContent: "center", marginTop: "100px"}}>
          <p>{t("You must log in to view the page")}</p>
          <button
            onClick={handleLogin}
            style={{fontSize: "22px", cursor: "pointer", padding: "5px"}}
            disabled={loading}
          >{t('Login')}
          </button>
        </div>
      </>
    )
  }

export default Login