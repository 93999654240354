import { useTranslation } from "react-i18next"
import { Box, TextField } from "@mui/material"
import { useStore } from "../../../contexts/store"
import { ChangeEvent } from "react"
import { observer } from "mobx-react-lite"
import { iLink } from "../../../../../types/Interaction"

const LinkTextarea = ({link}: {link: iLink}) => {
  const { t } = useTranslation()
  const { podStore } = useStore()
  const placeholder = t('Link description')

  return (
    <Box  sx={{display: "grid", gridTemplateRows: "min-content min-content", margin: "10px 15px"}}>
      <Box sx={{color: "grey", fontSize: "14px"}}>{t('Link description')}</Box>
      <TextField
      sx={{backgroundColor:"white"}}
        minRows={3}
        maxRows={3}
        multiline
        onChange={(e: ChangeEvent<HTMLInputElement>) => podStore.setLinkLabel(link.interactionId, e.target.value)}
        placeholder={placeholder}
        value={podStore.getLinkLabel(link.interactionId, link.label)}
        variant="outlined"
      />
    </Box>
  )
}

export default observer(LinkTextarea)