import i18next from 'i18next'
import { makeObservable, observable, action } from 'mobx'
import sessionStore from './sessionStore'
import alertStore, {alert} from "../stores/alertStore"
import exportOpStore from './opStore'

export interface broadcastStoreModel {
    serviceWorkerStatus: {[key: string]: any}
    createBroadcastChannel: () => void
    removeBroadcastChannel: () => void
    sendMessage: (msg: any) => void
  }


class broadcastStore {
    broadcastChannel: BroadcastChannel | null = null

    serviceWorkerStatus: {[key: string]: any} = {
        syncQueueLength: 0,
        fingerprints: {}
    }

    constructor() {
        makeObservable(this, {
            broadcastChannel: observable,
            serviceWorkerStatus: observable,
            gotMessage: action,
            createBroadcastChannel: action,
            removeBroadcastChannel: action,
            sendMessage: action,
        })
    }

    createBroadcastChannel() {
        if(this.broadcastChannel === null) {
            // TODO: use sessionId or some uuid as name to provide security
            const channel: BroadcastChannel = new BroadcastChannel('03dbc9e7-db11-4a22-b4fe-938b73d68653')
            this.broadcastChannel = channel
            // listen to messages from other tabs with the shrimp application
            channel.addEventListener('message', this.gotMessage.bind(this))
        }
    }

    removeBroadcastChannel() {
        this.broadcastChannel?.removeEventListener('message', this.gotMessage)
        this.broadcastChannel?.close()
        this.broadcastChannel = null
    }

    // TODO: user typescript object
    sendMessage(msg: any) {
        // TODO: open new broadcast channel if no one exists
        if (!this.broadcastChannel) {
            this.createBroadcastChannel()
        }
        if (this.broadcastChannel) {
            this.broadcastChannel.postMessage(msg)
        }
        else {
            console.error("Failed to send message. There is no broadcast channel.")
        }
    }

    gotMessage(ev: MessageEvent) {
        if(ev && ev.data && ev.data.op) {
            let msg = ev.data
            switch (msg.op) {
                case "syncMsg":
                    exportOpStore.importOps(msg.ops)
                    break;

                case "setServiceWorkerStatus":
                    this.serviceWorkerStatus = msg.serviceWorkerStatus
                    break

                case "logout":
                    if(sessionStore.session) {
                        sessionStore.clearSession()
                        alertStore.push(alert(i18next.t('You have been successfully logged out from another window'), 'success'))
                    }
                    break;
                default:
                    console.log(`BroadcastStore: received unknown broadcast message ${msg.op}`)
                    break;
            }
        } else {
            console.error("Incoming message has no data or op property", ev)
        }
    }

}

const exportBroadcastStore = new broadcastStore()
export default exportBroadcastStore
