import { useLocation } from "wouter"
import { Box, Divider, Paper } from "@mui/material"
import { useStore } from '../../../contexts/store'
import { iAnnotation, iComment, iLink, iTag } from "../../../../../types/Interaction"
import AnnotationIcon from '@mui/icons-material/FormatColorText'
import CommentIcon from '@mui/icons-material/Comment'
import LinkIcon from '@mui/icons-material/Link'
import TagIcon from '@mui/icons-material/Sell'
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import Note from "../Note"
import InteractionPanelLink from "./InteractionPanelLink"
import InteractionPanelComment from "./InteractionPanelComment"
import InteractionPanelTag from "./InteractionPanelTag"
import InteractionPanelMenu from "./InteractionPanelMenu"
import { useParams } from "../../../stores/uiStore"

type InteractionType = {
  color: string,
  id: string,
  type: string,
  pageNumber: number,
  text: string,
  y: number,
  x: number,
  obj: iAnnotation | iComment | iLink | iTag
}


const InteractionPanel = ({pageListRef}: {pageListRef: any}) => {
  const [location, navigate] = useLocation()
  const { t } = useTranslation()
  const { podStore, uiStore } = useStore()
  const { podId, pdfId, interactionId } = useParams()

  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`
  // prevent errors if there is no pdf id
  if(podId === null || pdfId === null) return null

  // where no distinction between interactions is necessary, use interactionId to simplify the code
  //let interactionId: string | null = null

  // load annotations for pdf with pdfId
  const annotations: iAnnotation[] | null = podStore.getAnnotations(pdfId, null)
  // load comments for pdf with pdfId
  const comments: iComment[] | null = podStore.getComments(pdfId, null)
  // load links for pdf with pdfId
  const links: iLink[] | null = podStore.getLinks(pdfId, null)
  // load tag for pdf with pdfId
  const tags: iTag[] | null = podStore.getTags(pdfId, null)

  // create list containing all interactions
  const interactionList: InteractionType[] = []
  // add all annotations to interactionList
  annotations?.forEach((annotation: iAnnotation) => {
    if(annotation.anchor.rects.length) {
      interactionList.push({
        color: annotation.style.color ? annotation.style.color : uiStore.getOpColor("annotation"),
        id: annotation.interactionId,
        type: "annotation",
        pageNumber: annotation.anchor.rects[0].p,
        text: annotation.anchor.relText,
        x: annotation.anchor.rects[0].x,
        y: annotation.anchor.rects[0].y,
        obj: annotation
      })
    }
  })
  // add all comments to interactionList
  comments?.forEach((comment: iComment) => {
    if(comment.anchor.rects.length) {
      interactionList.push({
        color: comment.style.color ? comment.style.color : uiStore.getOpColor("comment"),
        id: comment.interactionId,
        type: "comment",
        pageNumber: comment.anchor.rects[0].p,
        text: comment.anchor.relText,
        x: comment.anchor.rects[0].x,
        y: comment.anchor.rects[0].y,
        obj: comment
      })
    }
  })
  // add all links to interactionList
  links?.forEach((link: iLink) => {
    if(link.anchor.rects.length) {
      interactionList.push({
        color: link.style?.color ? link.style.color : uiStore.getOpColor("link"),
        id: link.interactionId,
        type: "link",
        pageNumber: link.anchor.rects[0].p,
        text: link.anchor.relText,
        x: link.anchor.rects[0].x,
        y: link.anchor.rects[0].y,
        obj: link
      })
    }
  })
  // add all tags to interactionList
  tags?.forEach((tag: iTag) => {
    if(tag.anchor.rects.length) {
      interactionList.push({
        color: tag.style?.color ? tag.style.color : uiStore.getOpColor("tagging"),
        id: tag.interactionId,
        type: "tagging",
        pageNumber: tag.anchor.rects[0].p,
        text: tag.anchor.relText,
        x: tag.anchor.rects[0].x,
        y: tag.anchor.rects[0].y,
        obj: tag
      })
    }
  })

  // sort order of interactions
  interactionList.sort((a, b) => {
    // compare by page number
    if (a.pageNumber > b.pageNumber) return 1
    if (a.pageNumber < b.pageNumber) return -1
    // interaction are on the same page: compare vertical position of the first rectangle
    if(a.y > b.y) return 1
    if(a.y < b.y) return -1
    // interactions are on the same height: compare horizontal position of the first rectangle
    if(a.x > b.x) return 1
    if(a.x < b.x) return -1
    // interactions share the same position of the first rectangle
    return 0
  })

  // scroll pdf to the selected interaction
  const scrollToInteraction = (pageNumber: number, interactionClickId: string) => {
    // do not scroll when interaction is deselected
    if(pageListRef.current && interactionClickId !== interactionId) {
      pageListRef.current.scrollToItem(pageNumber-1, "start")
    }
  }

  // if interaction is already selected, hide interaction, otherwise show interaction
  const highlightInteraction = (interaction: InteractionType) => {
    // interaction already selected
    if(interaction.id === interactionId) return
    let linkDestination = null
    if(interaction.type === "annotation") linkDestination = `${pdfUrl}/annotation/${interaction.id}`
    if(interaction.type === "comment") linkDestination = `${pdfUrl}/comment/${interaction.id}`
    if(interaction.type === "link") linkDestination = `${pdfUrl}/link/${interaction.id}`
    if(interaction.type === "tagging") linkDestination = `${pdfUrl}/tag/${interaction.id}`
    // highlight selected interaction
    if(linkDestination) navigate(linkDestination, {replace: true})
  }

  // if annotation is not selected and has a label: show it
  // if annotation is selected: show note
  const AnnotationLabel = ({interaction}: {interaction: InteractionType}) => (
    <>
      {(interaction.id !== interactionId && interaction.type === "annotation" && interaction.obj.label) && (
        <Paper sx={{margin: "10px 10px 20px 30px", p: "15px", backgroundColor: "#ffea77", borderLeft: `8px solid ${interaction.color}`}}>
          {interaction.obj.label}
        </Paper>
      )}
      {(interaction.id === interactionId && interaction.type === "annotation") && (
        <Note podId={podId} pdfId={pdfId} annotationId={interaction.id} />
      )}
    </>
  )

  // elements of the corresponding interaction
  const InteractionContent = ({interaction}: {interaction: InteractionType}) => {
    const isActive = interaction.id === interactionId ? true : false
    if(interaction.type === "comment") return <InteractionPanelComment commentId={interaction.id} isActive={isActive} />
    if(interaction.type === "link") return <InteractionPanelLink linkId={interaction.id} isActive={isActive} podId={podId} pdfId={pdfId}/>
    if(interaction.type === "tagging") return <InteractionPanelTag tag={interaction.obj as iTag} isActive={isActive} />
    return null
  }

  // element that displays the interactions
  const Interaction = ({interaction}: {interaction: InteractionType}) => (
    <Box onClick={() => highlightInteraction(interaction)}>
        <Box sx={{
          backgroundColor: interaction.id === interactionId ? "#e3e3ff" : "whitesmoke",
          border: interaction.id === interactionId ? "3px solid #6c6cd3" : "1px solid grey",
          borderRadius: "5px",
          display: "grid",
          margin: "10px",
          minHeight: "80px",
          padding: "10px",
          '&:hover': {
            background: interaction.id === interactionId ? "#e3e3ff" : "#e8e8e8",
          }
        }}>
          {/* header */}
          <Box sx={{
            alignItems: "center",
            display: "grid",
            gridGap: "10px",
            gridTemplateColumns: "min-content auto min-content",
            padding: "2px",
            paddingBottom: "5px"
          }}>
            {interaction.type === "annotation" && <AnnotationIcon />}
            {interaction.type === "comment" && <CommentIcon />}
            {interaction.type === "link" && <LinkIcon />}
            {interaction.type === "tagging" && <TagIcon />}
            <Box sx={{fontWeight: "bold", alignSelf: "center", fontSize: "13px"}}> {t('Page')} {interaction.pageNumber}</Box>
            <InteractionPanelMenu
              interactionId={interaction.id}
              interactionType={interaction.type}
              podId={podId}
              pdfId={pdfId}
            />
          </Box>
          <Divider />
          {/* selected text associated with the interaction */}
          <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", backgroundColor: "whitesmoke", fontStyle: "italic", margin: "10px 5px 5px 5px"}}>
            <Box sx={{backgroundColor: interaction.color, width: "6px", m: "5px"}} />
            <Box sx={{padding: "10px 20px 10px 10px", wordBreak: "break-word"}}>
              {interaction.text.length <= 100 ? interaction.text: (interaction.text.substring(0, 100) + "...")}
            </Box>
          </Box>
          {/* interaction content */}
          <Box>
            <InteractionContent interaction={interaction} />
          </Box>
        </Box>
      <AnnotationLabel interaction={interaction} />
    </Box>
  )

  return (
    <Box sx={{overflowY: "auto", backgroundColor: "#6c6cafb8"}}>
      <ul style={{padding: 0, margin: 0}}>
        {interactionList.map(interaction =>
          <Interaction key={interaction.id} interaction={interaction}/>
        )}
      </ul>
    </Box>
  )
}

export default observer(InteractionPanel)