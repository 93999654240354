import React from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'
import podStore from '../../stores/podStore';
import { useLocation } from "wouter";

const SyncStatus: React.FC = () => {
  const { broadcastStore, opStore } = useStore()
  const [location, navigate] = useLocation()

  const clientQueueLength = opStore.static.queue.length ? opStore.static.queue.length : 0
  // const syncStatus = opStore.static.busy ? '*' : '.'
  var serviceWorkerPodFingerprint = '...'
  if (podStore.pod?.podId) {
    if (broadcastStore.serviceWorkerStatus.fingerprints) serviceWorkerPodFingerprint = broadcastStore.serviceWorkerStatus.fingerprints[podStore.pod.podId]
  }
  var localPodFingerprint = '...'
  if (podStore.pod?.podId) {
    localPodFingerprint = podStore.pod.fingerprint()
  }

  const style = {
    fontSize:9,
    color: opStore.static.busy ? '#f00' : '#fff',
    borderLeft: opStore.static.busy ?  '4px solid red' : '1px solid black',
  }

  const fingerprintStyle = (localPodFingerprint !== serviceWorkerPodFingerprint) ? { color: 'orange' } : {}

  return <table style={style} border={0} cellPadding={0} cellSpacing={0} onClick={() => { navigate('/system') }}>
    <tbody>
      <tr><td>Client: </td><td>{clientQueueLength}</td></tr>
      <tr><td>ServiceWorker:&nbsp;</td><td>{broadcastStore.serviceWorkerStatus.syncQueueLength}</td></tr>
      <tr style={fingerprintStyle}><td>{localPodFingerprint}</td><td>{serviceWorkerPodFingerprint}</td></tr>
    </tbody>
  </table>
}

export default observer(SyncStatus)