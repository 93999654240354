import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { iTag } from "../../../../../types/Interaction"
import { Box, Chip } from "@mui/material"

const InteractionPanelTag = ({tag, isActive}: {tag: iTag, isActive: boolean}) => {
  const { podStore } = useStore()

  const tagProp = podStore.getTagProp(tag.tagId)

  // only show tag if there is a tag property
  if(!tagProp) return null

  return (
    <Box sx={{margin: "10px", fontWeight: "bold"}}>
      <Chip color="secondary" variant="outlined" label={`# ${tagProp.name}`} />
    </Box>
  )
}

export default observer(InteractionPanelTag)