import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { useStore } from '../../../contexts/store'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { InteractionType } from '../../../../../types/Interaction'
import { OpCode } from '../../../../../types/OpCodes'

const InteractionPanelMenu = ({
  interactionId,
  interactionType,
  podId,
  pdfId
}: {
  interactionId: string,
  interactionType: string,
  podId: string,
  pdfId: string
}) => {
  const { t } = useTranslation()
  const [location, navigate] = useLocation()
  const { opStore, uiStore, podStore } = useStore()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function editInteraction(event: React.MouseEvent<HTMLElement>, interactionId: string) {
    event.stopPropagation()
    handleClose()
    // if link: navigate to editLink view
    if(interactionType === "link") navigate(`/pod/${podId}/pdf/${pdfId}/editLink/${interactionId}`)
    // otherwise: open edit modal
    else uiStore.openInteractionModal(interactionId)
  }

  function deleteInteraction(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    handleClose()
    const warningText = t('Do you really want to delete this interaction?')
    if (window.confirm(warningText)) {
      let op
      if(interactionType === "annotation" as InteractionType) op = "deleteAnnotation" as OpCode
      else if(interactionType === "comment" as InteractionType) op = "deleteComment" as OpCode
      else if(interactionType === "link" as InteractionType) op = "deleteLink" as OpCode
      else if(interactionType === "tagging" as InteractionType) op = "deleteTagging" as OpCode
      else {
        console.warn(`interaction type ${interactionType} is not supported`)
        return
      }
      opStore.doOp({
        'op': op,
        usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
        podId, data: { interactionId: interactionId }
      })
    }
  }

  return (
      <>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{m: 0, p: 0}}
        >
            <MenuList sx={{m: 0, p: 0}}>
              <MenuItem onClick={(event) => editInteraction(event, interactionId)} sx={{p: "10px 20px"}}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('Edit')}</ListItemText>
              </MenuItem>
              <MenuItem onClick={deleteInteraction} sx={{p: "10px 20px", color: "#d24b4e"}}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" sx={{color: "#d24b4e"}} />
                </ListItemIcon>
                <ListItemText>{t('Delete')}</ListItemText>
              </MenuItem>
            </MenuList>
        </Menu>
    </>
  )
}

export default InteractionPanelMenu