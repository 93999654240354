import i18next from 'i18next'
import { makeObservable, action, observable } from "mobx"
import alertStore, {alert} from "./alertStore"
import { InteractionType, interactionAnchor } from '../../../types/Interaction'
import { OpCode } from "../../../types/OpCodes"

import { useLocation } from 'wouter'

export const useParams = () => {
  const [ location ] = useLocation()
  let parms:RegExpMatchArray | null = null
  let urlParams:{[key:string]:string} = {}

  parms = location.match(/^\/pod\/([^/]+)\/?(pdf\/([^/]+))?\/?(([a-zA-Z]+)\/([^/]+))?/)
  if (parms && parms[1]) urlParams.podId  = parms[1]
  if (parms && parms[3]) urlParams.nodeId = urlParams.pdfId = parms[3]      // best practice: use nodeId, but we will provide pdfId for legacy reasons
  if (parms && parms[5]) urlParams.interactionType = parms[5]
  if (parms && parms[6]) urlParams.interactionId = parms[6]
    
  return urlParams
}

export interface uiStoreModel {
    annotationColors: string[],
    isOffline: boolean,
    selectedAnchor: interactionAnchor | null,
    showVerboseLogging: {[feature:string]: boolean},
    interactionModal: {isOpen: boolean, interactionId: string | null},
    openInteractionModal: (interactionId?: string) => void,
    closeInteractionModal: () => void,
    getSelectedInteraction: () => OpCode,
    getSemiTransparentColor: (color: string) => string,
    getAnnotationColor: () => string,
    setSelectedAnchor: (anchor: interactionAnchor | null) => void,
    setSelectedInteraction: (interactionType: OpCode) => void,
    setAnnotationColor: (color: string) => void,
    setIsOffline(isOffline: boolean): void,
    getOpColor: (op: OpCode | InteractionType) => string

    urlParams: {[key:string]: string}
    setUrlParams: (key:string, value:string) => void  
  }

class uiStore {
  // default ui settings
  isOffline = false
  annotationColors = [
    "#DC267F",
    "#FE6100",
    "#FFB000"
  ]
  selectedAnnotationColor = this.annotationColors[0]
  selectedAnchor: interactionAnchor | null = null
  interactionModal: {isOpen: boolean, interactionId: string | null} = {isOpen: false, interactionId: null}
  selectedInteractionType: OpCode = "addAnnotation"
  showVerboseLogging = {
    sync: false,
    loadPod: false,
    opProcessing: false,
  }
  overlayColorAlpha = 0.5
  urlParams: {[key:string]: string} = {}

  constructor() {
    makeObservable(this, {
      annotationColors: observable,
      isOffline: observable,
      getSelectedInteraction: observable,
      getSemiTransparentColor: action,
      getAnnotationColor: observable,
      selectedAnchor: observable,
      selectedAnnotationColor: observable,
      selectedInteractionType: observable,
      showVerboseLogging: observable,
      setSelectedAnchor: observable,
      setSelectedInteraction: action,
      setAnnotationColor: action,
      setIsOffline: action,
      interactionModal: observable,
      openInteractionModal: action,
      closeInteractionModal: action,

      urlParams: observable,
      setUrlParams: action,
    })
  }

  setUrlParams(key:string, value: string = '') {
    if (value) this.urlParams[key] = value; else delete this.urlParams[key]
  }

  getSelectedInteraction() {
    return this.selectedInteractionType
  }

  getOpColor(op: OpCode | InteractionType) {
    if(op === "annotation" || op === "addAnnotation") return this.selectedAnnotationColor
    if(op === "comment" || op === "addComment") return "#785EF0"
    if(op === "link" || op === "addLink") return "darkturquoise"
    if(op === "tagging" || op === "addTagging") return "purple"
    console.warn('unexpected operation in getOpColor', op)
    return "gray"
  }

  getSemiTransparentColor(color : string) {
    // use only if color from annotations palette to ensure compatibility with imported colors
    if(this.annotationColors.includes(color)) {
      const semiTransparentColor = `${color}${Math.floor(this.overlayColorAlpha * 255).toString(16).padStart(2, '0')}`
      return semiTransparentColor
    }
    return color
  }

  getAnnotationColor() {
    return this.selectedAnnotationColor
  }

  setSelectedAnchor(anchor: interactionAnchor | null) {
    this.selectedAnchor = anchor
  }

  setSelectedInteraction(interactionType: OpCode) {
    this.selectedInteractionType = interactionType
  }

  setAnnotationColor(color: string) {
    this.selectedAnnotationColor = color
  }

  setIsOffline(isOffline: boolean) {
      // switch from online to offline mode
      if(!this.isOffline && isOffline) {
          alertStore.push(alert(i18next.t('changes will be saved locally until the next time you go online again'), 'info', i18next.t('You are in Offline-Mode') ?? '', 'WifiOffIcon'))
      }
      // switch from offline to online mode
      if(this.isOffline && !isOffline) {
          alertStore.push(alert(i18next.t('your local changes are now synchronized with the server'), 'success', i18next.t('You are online again') ?? '', 'WifiOnIcon'))
      }
      this.isOffline = isOffline
  }

  openInteractionModal(interactionId?: string) {
    if(interactionId) {
      this.interactionModal = {isOpen: true, interactionId: interactionId}
    }
    else {
      this.interactionModal = {isOpen: true, interactionId: null}
    }
  }

  closeInteractionModal() {
    this.interactionModal = {isOpen: false, interactionId: null}
  }
}


const exportUiStore = new uiStore()
export default exportUiStore
