import { useEffect, useState } from "react"
import { useLocation } from "wouter"
import { useStore } from "../../contexts/store"
import { useParams } from "../../stores/uiStore"

let previousInteractionId:string|undefined = undefined

const ScrollPdfViewer = ({
    storeId,
    pageListRef
  }: {
    storeId: string,
    pageListRef: any
  }) => {
  const { interactionId }  = useParams()
  const { pdfMetaStore, podStore } = useStore()
  const [ prevInteractionId, setPrevInteractionId ] = useState<string|undefined>(undefined)

  const getPageNumber = (interactionId:string) => {
    const location = podStore.pod.findInteraction(interactionId)
    if (location) {
      const file = podStore.pod.content[location.contentType][location.nodeId]
      const interaction = file[location.interactionType][interactionId]
      if (interaction) {
       return interaction.anchor.rects[0].p
      } 
    }
    console.warn(`Did not find interaction ${interactionId}`)
    return undefined
  }

  useEffect(() => {
    if ((interactionId) && (interactionId !== prevInteractionId)) {
      const pagenumber = getPageNumber(interactionId)
      if (pagenumber) {
        if ((pageListRef) && (pageListRef.current)) {
          pageListRef.current.scrollToItem(pagenumber-1, "start")
          setPrevInteractionId(interactionId)
        }
        else {
          pdfMetaStore.setPagePos(storeId, [pagenumber-1,pagenumber])
          setPrevInteractionId(interactionId)
        }
      }
    }
  })

  return null
}

export default ScrollPdfViewer