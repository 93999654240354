export const versions:{version:number, key:string, description:string}[] = [
  {
    version: 0.401,
    key:'KEV-Debug',
    description: `Kevin: Some additional debugging work: version included as a header in the API-fetch; errror boundaries.`,
  },
  {
    version: 0.405,
    key:'Wouter full',
    description: `Kevin: Fully on Wouter now, including error boundaries.`,
  },
]