import React from "react"
import { useTranslation } from 'react-i18next'
import { Box, FormControl, MenuItem, Select } from "@mui/material"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const ScaleSelector = ({
    pageListRef,
    storeId
  }: {
    pageListRef: any,
    storeId: string
  }) => {
  const { t } = useTranslation()
  const { pdfMetaStore } = useStore()
  const scale = pdfMetaStore.getScale(storeId)
  const scaleSelection = pdfMetaStore.getScaleSelection(storeId)

  const handleScaleChange = (scaleSelection: number | string) => {
    // update component state
    pdfMetaStore.setScaleSelection(storeId, scaleSelection)

    let scaleSelect = 1
    const pdfWidths = pdfMetaStore.getPageWidths(storeId)
    const pdfHeights = pdfMetaStore.getPageHeights(storeId)

    if(scaleSelection === 'PageWidth' && pdfWidths && scale) {
      if(pageListRef && pageListRef.current && pageListRef.current.props) {
        const listWidth = pageListRef.current.props.width
        const pdfWidth = pdfWidths[0]
        const yOffset = 40
        scaleSelect = (listWidth-yOffset)/(pdfWidth/scale)
      }
    }
    else if(scaleSelection === 'FullPage' && pdfHeights && scale) {
      if(pageListRef && pageListRef.current && pageListRef.current.props) {
        const listHeight = pageListRef.current.props.height
        const pdfHeight = pdfHeights[0]
        const xOffset = 20
        scaleSelect = (listHeight-xOffset)/(pdfHeight/scale)
      }
    }
    else {
      scaleSelect = Number(scaleSelection)
    }
    // round to percent without comma
    scaleSelect = Math.round(scaleSelect*100)/100
    // update scale in store
    pdfMetaStore.setScale(storeId, Number(scaleSelect))
  }

  return (
    <Box sx={{ minWidth: 70 }}>
      <FormControl fullWidth sx={{ backgroundColor: "whitesmoke" }} size="small">
        <Select
          value={scaleSelection}
        >
          <MenuItem value={'PageWidth'} onClick={()=> handleScaleChange("PageWidth")}>{t('Page Width')}</MenuItem>
          <MenuItem value={'FullPage'} onClick={()=> handleScaleChange("FullPage")}>{t('Full Page')}</MenuItem>
          <MenuItem value={0.5} onClick={()=> handleScaleChange(0.5)}>50%</MenuItem>
          <MenuItem value={0.7} onClick={()=> handleScaleChange(0.7)}>70%</MenuItem>
          <MenuItem value={0.85} onClick={()=> handleScaleChange(0.85)}>85%</MenuItem>
          <MenuItem value={1} onClick={()=> handleScaleChange(1)}>100%</MenuItem>
          <MenuItem value={1.25} onClick={()=> handleScaleChange(1.25)}>125%</MenuItem>
          <MenuItem value={1.5} onClick={()=> handleScaleChange(1.5)}>150%</MenuItem>
          <MenuItem value={1.75} onClick={()=> handleScaleChange(1.75)}>175%</MenuItem>
          <MenuItem value={2} onClick={()=> handleScaleChange(2)}>200%</MenuItem>
          <MenuItem value={3} onClick={()=> handleScaleChange(3)}>300%</MenuItem>
          <MenuItem value={4} onClick={()=> handleScaleChange(4)}>400%</MenuItem>
          <MenuItem value={8} onClick={()=> handleScaleChange(8)}>800%</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default observer(ScaleSelector)
