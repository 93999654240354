import { makeObservable, action, observable } from "mobx"
import throttle from '@jcoreio/async-throttle'

import exportSessionStore from './sessionStore'
import exportBroadcastStore from "./broadcastStore"
import alertStore, { alert } from '../stores/alertStore'
import { PodClass } from '../classes/Pod'
import podStore from './podStore'
import api from '../api/api'
import exportUiStore from "./uiStore"
import { PodLoadState, syncPullRequest } from "../../../types/Pod"
import { Op, OpSkeleton } from "../../../types/Ops"
import { OpCode } from "../../../types/OpCodes"
// import i18next from 'i18next'


export interface OpStoreModel {
  doOp: (op: OpSkeleton) => void,
  syncFull: () => void,
  static: { busy: boolean, queue: Array<Op>, swStatus: number },
}


class OpStore {

  static = {
    busy: false,
    queue: Array(),
    swStatus: 0,
  }
  
  constructor() {
    makeObservable(this, {
      static: observable,
      importOps: action,
      setSyncBusy: action,
      getSyncableOps: action,
      setSyncables: action,
      doOp: action,
    })
  }

  setSyncBusy(busy:boolean) {
    this.static.busy = busy
  }

  getSyncableOps(watermark:number) {
    return this.static.queue.splice(0, watermark)
  }

  setSyncables(syncables: Array<object>) {
    this.static.queue.unshift(...syncables)
  }

  setSwStatus(queueLength: number) {
    this.static.swStatus = queueLength
  }

  /**
   * Import operations that have been delivered via broadcast (from another tab / from the serviceWorker)
   */
  importOps(ops: Op[]) {
    // Apply the ops to the currently loaded pod
    ops.forEach((op:any) => { 
      this.execute(podStore.pod, op)
    })

    // Re-Apply all local ops that have not yet been transmitted to the ServiceWorker (if applicable) or the backend
    this.static.queue.forEach((op:Op) => {
      console.log(`reapply ${op.op}`)
      this.execute(podStore.pod, op)
    })
  }

  syncFull() {
    this.throttledSyncFull()
  }

  throttledSyncFull = throttle(async () => {
    this.setSyncBusy(true)
    const watermark = this.static.queue.length
    const syncables = this.getSyncableOps(watermark)
    
    if (!exportSessionStore.session?.sessionId) return false

    try {
      var pulling:Array<syncPullRequest> = []
      if ((podStore.pod?.podId) && (podStore.pod?.status === 'loaded' as PodLoadState)) pulling = [{podId: podStore.pod.podId, lastSyncOid: podStore.pod.lastSyncOid}]

      const res = await api.syncFull(pulling, syncables)
      
      if ((res) && (res.status === 401)) {
        console.log('API.syncFull() resulted in 401')
        exportSessionStore.clearSession()
        // todo: use i18n
        alertStore.push(alert('Your session has expired. Please log in again', 'warning'))
        exportBroadcastStore.sendMessage({op: "logout"})
      } else if ((res) && (res.status === 202)) {
        if (exportUiStore.showVerboseLogging.sync) console.log('API.syncFull() was handled by serviceWorker')
      }
      else if ((res) && (res.status === 200)) {
        console.warn('The API Sync-call seems to have been handled by the backend directly (without a Service Worker). This is not yet implemented..')
      }
      else {
        console.warn('API call to syncFull resulted in:', res)
      }
    }
    catch(e) {
      console.error(e)
      this.setSyncables(syncables)      
    }
    this.setSyncBusy(false)
  }, 2000, { } )

  /** Apply single operation to a given Pod
   *  Include side-effects, such as updating the Pod overview, when the Pod's name etc. changes
   */
  execute(pod: PodClass | null, op: Op) {
    // stop execution if pod is null
    if(pod === null) {
      console.warn("Could not execute operation, no pod available.", op)
      return
    }

    switch(op.op) {

      // All these are pure pod operations with no side effects outside the pod
      case 'noop':
      case 'addAnnotation':
      case 'addComment':
      case 'addLink':
      case 'addWeblink':
      case 'addTagging':
      case 'addEmotion':
      case 'addFolder':
      case 'addPdfFile':
      case 'addTag':
      case 'editPdf':
      case 'addPdfPage':
      case 'editAnnotation':
      case 'editComment':
      case 'editLink':
      case 'editTagging':
      case 'deleteAnnotation':
      case 'deleteComment':
      case 'deleteLink':
      case 'deleteWeblink':
      case 'deleteTagging':
      case 'addThread':
      case 'addMessage':
        
        if ((pod) && (pod.podId === op.podId)) {
          // console.log(`Executing ${op.op}:`, op)
          pod.applyOp(op)
        } else {
          console.warn(`Did not execute ${op.op} because the corresponding pod ${op.podId} was not present:`, op)
        }
          
        break;

      default:
        console.warn(`Unknown op ${op.op}`)
    }
  }

  /** Process an operation. This includes
   *  1) adding missing information to make sure it is a complete OP
   *  2) executing it, which will include side effects
   *  3) syncing it to the ServiceWorker / Backend
   */
  doOp(miniOP: OpSkeleton) {
    
    const op:Op = miniOP as Op
    const addOperations:OpCode[] = Array('addPdfFile', 'addPdfPage', 'addFolder', 'addAnnotation', 'addComment', 'addLink', 'addWeblink', 'addTag', 'addEmotion','addThread','addMessage')

    // add missing information
    if (!op.opLogId)   op.opLogId  = exportSessionStore.createUuid()
    
    // Since addOperations contain the original object to be added, make sure its coid is set (as null)
    if (addOperations.includes(op.op)) {
      if (!op.data.coid) op.data.coid = null
      if (!op.data.tCreated) op.data.tCreated   = null
      if (!op.data.tModified) op.data.tModified = null
    }
    
    // Execute the op on the loaded podStore (or on null) for possible side effects
    if (podStore.pod !== null) this.execute(podStore.pod, op); else this.execute(null, op)

    // Queue this OP for syncing (to the SW and, eventually, the backend)
    this.static.queue.push(op)
    exportBroadcastStore.sendMessage({op: 'syncMsg', ops: [op]})
    this.throttledSyncFull()
  }

}

const exportOpStore = new OpStore()
export default exportOpStore