import React from "react"
import { styled } from '@mui/system'
import { observer } from "mobx-react-lite"
import ScaleSelector from "./Toolbar/ScaleSelector"
import ColorSelector from "./Toolbar/ColorSelector"
import InteractionSelector from "./Toolbar/InteractionSelector"

const Sidebar = ({
    pageListRef,
    storeId
  }: {
    pageListRef: any,
    storeId: string
  }) => {

  return (
    <ToolbarContainerStyle>
      <ScaleSelector
        pageListRef={pageListRef}
        storeId={storeId}
      />
      <InteractionSelector />
      <ColorSelector />
    </ToolbarContainerStyle>
  )
}

export default observer(Sidebar)


// style components -------------------------------------------------

const ToolbarContainerStyle = styled('div')({
  alignContent: "start",
  backgroundColor: "#6c6caf",
  display: "grid",
  gridGap: "10px",
  justifyContent: "center",
  padding: "10px"
})