import React from "react"
import { useTranslation } from 'react-i18next'

const ErrorFallback = ({
  error, resetErrorBoundary, context:undefined, reportId=0
}: {
  error:any, 
  resetErrorBoundary:any, 
  context?: string|undefined, 
  reportId?:number,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <h1>{t('Apologies! It seems that there was an error')}... {reportId ? `(Reported #${reportId})` : null}</h1>
      <p style={{color:'red'}}>{error.message}</p>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </div>
  )
}

export default ErrorFallback