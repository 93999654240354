import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loading: React.FC = () => {
    return (
        <Backdrop
            sx={{ color: "#e8e8e8", backgroundColor: "inherit", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loading