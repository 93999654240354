import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link, useLocation } from 'wouter'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import { Box, Paper } from '@mui/material'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'


import { Pod } from '../../../../types/Pod'
import PodImpro from '../Dev/PodImpro'              // @hagen: bitte bis auf weiteres drin lassen. I know it's ugly, aber solange ich Deinen branch immer wieder bei mir reinmerge, ist das doof sonst :)

const Home: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore, podStore } = useStore()
  const { session } = sessionStore
  const pods = session.pods
  const [ location, navigate ] = useLocation()

  useEffect(() => {
    podStore.unsetPod()
  })

  // TODO: create message if there are no pods available
  if(pods === null) return <div>no pods available</div>

  const podsList = pods.map((pod: Pod) =>
    <li key={pod.podId}>
      <Link to={`pod/${pod.podId}`}  style={{textDecoration: "none"}}>
        <Paper elevation={2} sx={{height: "50px", display: "grid", gridTemplateColumns: "min-content auto", gridGap: "10px", alignContent: "center", p: 2, mt: 1, mb: 1, "&:hover": {backgroundColor: "whitesmoke"} }}>
          <FolderSpecialIcon />
          {pod.name}
        </Paper>
      </Link>
    </li>
  )

  return (
    <>
      <Box sx={{ display: "grid", gridTemplateRows: "auto", alignContent: "start", p: 5 }}>
        <h2>{t('Welcome to SHRIMP')}</h2>
        <ul  style={{listStyleType: "none", padding: 0, maxWidth: "1000px"}}>{podsList}</ul>
      </Box>
      <PodImpro />
    </>
  )

}

export default observer(Home)

