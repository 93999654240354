import { makeObservable, action, observable } from "mobx"

import opStore from './opStore'

import api from '../api/api'

import { PodLoadState, Usergroup } from '../../../types/Pod'
import { PodClass, PodI } from '../classes/Pod'
import { Interaction, InteractionType, Rect, iAnnotation, iComment, iEmotion, iLink, iTag, iWeblink, interactionAnchor } from '../../../types/Interaction'
import { Tag, fullLink } from "../../../types/Content"
import uiStore from "./uiStore"
import { Op } from "../../../types/Ops"

export interface PodStoreModel {
  pod: any
  podIsLoading: boolean
  activeLinkEditId: string | null,

  getAnnotations: (fileId: string, page: number | null) => Interaction[] | null
  getComments: (fileId: string, page: number | null) => Interaction[] | null
  getLinks: (fileId: string, page: number | null) => iLink[] | null
  getInteraction: (fileId: string, interactionId: string) => {object: iAnnotation | iComment | iLink | iTag | iEmotion, type: InteractionType} | null
  getLink: (fileId: string | undefined | null, interactionId: string | undefined | null) => fullLink | null
  getTags: (fileId: string, page: number | null) => iTag[] | null
  getTagProp: (tagId: string) => Tag | null
  getTagPool: () => Tag[]
  getUsergroupByRole: (role: string) => Usergroup

  setActiveLinkEditId: (linkEditId: string | null) => void
  loadPod: (podId: string) => Promise<boolean>
  unsetPod: () => void
  resetPod: (podId: string) => Promise<boolean>

  getLinkLabel: (linkId: string, linkLabel: string | null) => string
  setLinkLabel: (linkId: string, text: string) => void
  deleteLinkLabel: (linkId: string) => void
  getLinkFile: (linkId: string, fileId: string | null) => string
  setLinkFile: (linkId: string, fileId: string) => void
  deleteLinkFile: (linkId: string) => void
  getLinkOverlay: (linkInteractionId: string, anchor: interactionAnchor | null) => interactionAnchor
  setLinkOverlay: (linkInteractionId: string, anchor: interactionAnchor) => void
  deleteLinkOverlay: (linkId: string) => void
  getInteractionByCoordinates: (pdfId: string, x: number, y: number, page: number) => {type: InteractionType, clickedRect: Rect, interaction: Interaction} | null
  
}

type PodCondition = {
  status: PodLoadState,
  info: string
}

class podStore {
  podIsLoading: boolean = false
  activePodId: string | null = null
  activePdfId: string | null = null
  activeLinkEditId: string | null = null
  getOpsintervalId: number | null = null
  linkLabel: {[id: string]: string} = {}
  linkFile: {[id: string]: string} = {}
  linkOverlay: {[linkInteractionId: string]: interactionAnchor} = {}
  urlParams: {[key:string]: string} = {}

  // the currently loaded, active pod.
  pod: PodClass | null = null
  podCondition: {[podId: string]: PodCondition} = {}

  constructor() {
    makeObservable(this, {
      activePodId: observable,
      activePdfId: observable,
      activeLinkEditId: observable,
      pod: observable,
      podCondition: observable,
      setPod: action,
      setActiveLinkEditId: action,
      resetPod: action,
      unsetPod: action,
      setPodStatus: action,
      getAnnotations: observable,
      getComments: observable,
      getLinks: observable,
      getTags: observable,
      getInteraction: observable,
      getTagProp: observable,
      getTagPool: observable,
      linkLabel: observable,
      getLinkLabel: observable,
      setLinkLabel: action,
      deleteLinkLabel: action,
      linkFile: observable,
      getLinkFile: observable,
      setLinkFile: action,
      deleteLinkFile: action,
      linkOverlay: observable,
      getLinkOverlay: observable,
      setLinkOverlay: action,
      deleteLinkOverlay: action,
      getInteractionByCoordinates: action,
    })
  }

  async resetPod(podId: string) {
    delete this.podCondition[podId]
    if (podId === this.pod?.podId) this.unsetPod()
    await api.loadPod(podId, true)
    return true
  }

  /** trigger loading pod with podId */
  async loadPod(podId: string, force: boolean = false) {
    const t0 = Date.now()

    let pod: PodClass

    // Do not load pods that are already loaded
    if ((!force) && (this.pod?.podId === podId) && (this.pod.status === 'loaded')) {
      return true
    }

    if (this.pod?.status === 'loading') {
      console.warn(`Warning: loadPod() was called while a pod was loading. Cannot load pod ${podId} while ${this.pod.podId} is loading.`)
      return false
    }

    console.log(`Loading pod ${podId}`, this.pod)

    if (true) {
      pod = new PodClass(null, true)
      pod.podId = podId
      pod.status = 'loading'
      this.setPod(pod)
    }

    // Initialize with empty 'init' version of the pod (or get the full Pod if the serviceWorker has it)
    const loadedPod = await api.loadPod(podId)
    if (loadedPod) {
      pod = loadedPod
      if ((pod.lastSyncOid >= pod.initMaxCoid) && (pod.status === 'loaded')) {
        // query wurde vom SW mit einem vollständigen Pod beantwortet
        if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms. Fetch pending OPs.`)
        this.setPod(pod)
        return true
      }  
    }
    else {
      console.error(`Could not load pod`)
      //this.setPodStatus(pod, 'broken' as PodLoadState)
      return false
    }

    if (uiStore.showVerboseLogging.loadPod) console.log(`Pod was initialized but is incomplete: ${pod.lastSyncOid} !== ${pod.initMaxCoid} ||  ${pod.status} !== 'loaded' --> continue with chunked loading` )

    if (pod.status !== 'initialized') {
      console.warn(`Error condition: This should not happen! Pod status === ${pod.status}`)
      this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! Pod status === ${pod.status}`)
      this.unsetPod()
      return false
    }

    if (pod.lastSyncOid as number === pod.initMaxCoid as number) {
      console.warn(`Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
      this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
      this.unsetPod()
      return false
    }

    // Pod ist nur ein initPod: perform load
    this.setPodStatus(pod, 'loading' as PodLoadState)
    pod.setLoadStatus(0)
    let completedOpsCounter = 0

    this.setPod(pod)

    try {

      do {

        if (uiStore.showVerboseLogging.loadPod) console.log('loading chunk ' + pod.lastSyncOid)
        const chunk = await api.loadPodChunk(podId, pod.lastSyncOid, pod.initMaxCoid)
        
        if (chunk) {
          const { ops, totalOps } = chunk

          ops.forEach((op:any) => {
            opStore.execute(pod, op)
            pod.lastSyncOid = op.data.coid
            if (op.oid === pod.initMaxCoid) {
              this.setPodStatus(pod, 'loaded' as PodLoadState)
            }
          })
  
          completedOpsCounter += ops.length
          pod.setLoadStatus(totalOps ? Math.floor(100 * completedOpsCounter / totalOps) : 0)
  
          if (this.pod?.podId === pod.podId) this.setPod(pod)
        }

      } while(pod.status !== 'loaded' as PodLoadState)

      // Get unsynced OPs from serviceWorker and apply
      // (If they were created based on an older version of the pod, they will still get applied
      // based on this new version in the backend, so we should mimick this behavior here)
      const pendingOps = await api.getPendingOps(podId)
      if (pendingOps) {
        if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps.length} pending OPs`)
        pendingOps.forEach((op:Op) => {
          pod.applyOp(op)
        })
      }
    } catch(e) {
      console.error(`Error condition in fetch: `, e)
      this.setPodStatus(pod, 'broken' as PodLoadState)
      this.unsetPod()
      return false
    }

    if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms`)
    return true
  }

  setPodStatus(pod:PodI, status: PodLoadState, info: string = '') {
    if ((pod.status === 'loading') && (status === 'loaded')) pod.setLastSyncOid(pod.loadtimeMaxOid)
    pod.setStatus(status)
    this.podCondition[pod.podId] = {
      status: status,
      info,
    }
  }

  unsetPod() {
    this.pod = null
  }

  setPod(pod: PodI, info: string = '') {
    if (pod && pod.podId) {
      this.pod = pod
      if (pod.status) this.podCondition[pod.podId] = {
        status: pod.status,
        info,
      }
    }
  }

  getUsergroupByRole(role:string) {
    var usergroup:Usergroup = {
      usergroupId: 'Private',
      role: 'Private',
      name: 'Private',
      members: []
    }

    if (this.pod) Object.keys(this.pod.usergroups).forEach(usergroupId => {
      if ((this.pod) && (this.pod.usergroups[usergroupId].role === role)) usergroup = this.pod.usergroups[usergroupId]
    })

    return usergroup
  }

  getAnnotations(fileId: string, page: number | null = null) {
    const fileAnnotations = this.pod?.getAnnotations(fileId)
    if(!fileAnnotations) {
      console.error(`getAnnotations() could not find file ${fileId}`)
      return null
    }

    if (fileAnnotations && fileAnnotations.length) {
      if (page === null) return fileAnnotations
      return fileAnnotations.filter((annotation: iAnnotation) => {
        return annotation.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getComments(fileId: string, page: number | null = null) {
    const fileComments = this.pod?.getComments(fileId)
    if(!fileComments) {
      console.error(`getComments() could not find file ${fileId}`)
      return null
    }

    if (fileComments && fileComments.length) {
      if (page === null) return fileComments
      return fileComments.filter((comment: iComment) => {
        return comment.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getInteraction(fileId: string, interactionId: string) {
    const content = this.pod?.content?.pdfFiles[fileId]
    if(content) {
      const annotation = content.annotations[interactionId]
      if(annotation) return {object: annotation, type: "annotation" as InteractionType}
      const comment = content.comments[interactionId]
      if(comment) return {object: comment, type: "comment" as InteractionType}
      const link = content.links[interactionId]
      if(link) return {object: link, type: "link" as InteractionType}
      const tag = content.taggings[interactionId]
      if(tag) return {object: tag, type: "tagging" as InteractionType}
      console.warn("getInteraction: could not find any interaction matching the id", interactionId)
    }
    return null
  }

  getLinks(fileId: string, page: number | null = null) {
    const fileLinks = this.pod?.getLinks(fileId)
    if(!fileLinks) {
      console.error(`getLinks() could not find file ${fileId}`)
      return null
    }

    if (fileLinks && fileLinks.length) {
      if (page === null) return fileLinks
      return fileLinks.filter((link: iLink) => {
        return link.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []  
  }

  getLink(fileId: string | undefined | null, interactionId: string | undefined | null) {
    if(fileId && interactionId ) {
      const file = this.pod?.content?.pdfFiles[fileId]
      if(file) {
        const interactionLink = file.links[interactionId]
        // get link object with source and destination
        if(interactionLink) {
          const link = this.pod?.content.links[interactionLink.linkId]
          // console.log(`Found link ${interactionLink.linkId}`, JSON.stringify(link))
          if(link) return { 
            ...link,
            src: this.pod?.getInteraction(link?.src),
            dst: this.pod?.getInteraction(link?.dst),
          } as fullLink
        }
      }
    }
    // console.warn(`Could not find link-interaction ${interactionId} in file ${fileId}`)
    return null
  }

  getWeblinks(fileId: string, page: number | null = null) {
    const fileWeblinks = this.pod?.getWeblinks(fileId)
    if(!fileWeblinks) {
      console.error(`getComments() could not find file ${fileId}`)
      return null
    }

    if (fileWeblinks && fileWeblinks.length) {
      if (page === null) return fileWeblinks
      return fileWeblinks.filter((link: iWeblink) => {
        return link.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getTags(fileId: string, page: number | null = null) {
    const fileTags = this.pod?.getTags(fileId)
    if(!fileTags) {
      console.error(`getTags() could not find file ${fileId}`)
      return null
    }

    if (fileTags && fileTags.length) {
      if (page === null) return fileTags
      return fileTags.filter((interaction: Interaction) => {
        return interaction.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getTagProp(tagId: string) {
    const tagProp = this.pod?.content.tags[tagId]
    if(tagProp) return tagProp
    return null
  }

  getTagPool() {
    const tags = this.pod?.content.tags
    const tagPool = []
    if(tags) {
      for(let id in tags) {
        const tag = tags[id]
        if(tag.name) tagPool.push(tag)
      }
    }
    return tagPool
  }

  getInteractionByCoordinates(pdfId: string, x: number, y: number, page: number) {
    const list: {type: InteractionType, clickedRect: Rect, interaction: Interaction}[] = []

    // create a list of interactions that have a rectangle inside the click position
    const annotations: iAnnotation[] | null = this.getAnnotations(pdfId, page)
    if(annotations) {
      for(const annotation of annotations) {
        for(const rect of annotation.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) ) {
            list.push({type: "annotation", "clickedRect": rect, "interaction": annotation})
          }
        }
      }
    }
    const comments: iComment[] | null = this.getComments(pdfId, page)
    if(comments) {
      for(const comment of comments) {
        for(const rect of comment.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) ) {
            list.push({type: "comment", "clickedRect": rect, "interaction": comment})
          }
        }
      }
    }
    const links: iLink[] | null = this.getLinks(pdfId, page)
    if(links) {
      for(const link of links) {
        for(const rect of link.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) ) {
            list.push({type: "link", "clickedRect": rect, "interaction": link})
          }
        }
      }
    }
    const tags: iTag[] | null = this.getTags(pdfId, page)
    if(tags) {
      for(const tagging of tags) {
        for(const rect of tagging.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) ) {
            list.push({type: "tagging", "clickedRect": rect, "interaction": tagging})
          }
        }
      }
    }

    // if an interaction has a rectangle at the click position, return it
    if(list.length === 1) return list[0]
    // if multiple interactions have a rectangle at the click position, choose the rectangle with the smallest area
    if(list.length > 1) {
      let smallestArea: number | null = null
      let smallesAreaIndex: number = 0
      list.forEach((item, index) => {
        const rect = item.clickedRect
        const area = rect.w*rect.h
        if(smallestArea === null) {
          smallestArea = area
          smallesAreaIndex = index
        }
        if(smallestArea && area < smallestArea) {
          smallestArea = area
          smallesAreaIndex = index
        }
      })
      return list[smallesAreaIndex]
    }
    // there is no interaction at the click position
    return null
  }


  setActiveLinkEditId(linkEditId: string | null) {
    this.activeLinkEditId = linkEditId
  }

  getLinkLabel(linkId: string, linkLabel: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkLabel[linkId] === undefined && linkLabel) {
      this.linkLabel[linkId] = linkLabel ? linkLabel : ""
      return linkLabel
    }
    return this.linkLabel[linkId]
  }

  setLinkLabel(linkId: string, text: string) {
    this.linkLabel[linkId] = text
  }

  deleteLinkLabel(linkId: string) {
    if(this.linkLabel[linkId] || this.linkLabel[linkId] === "") delete this.linkLabel[linkId]
  }

  getLinkFile(linkId: string, fileId: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkFile[linkId] === undefined && fileId) {
      this.linkFile[linkId] = fileId
      return fileId
    }
    return this.linkFile[linkId]
  }

  setLinkFile(linkId: string, fileId: string) {
    this.linkFile[linkId] = fileId
  }

  deleteLinkFile(linkId: string) {
    if(this.linkFile[linkId]) delete this.linkFile[linkId]
  }

  getLinkOverlay(linkInteractionId: string, anchor: interactionAnchor | null) {
    // if label does not exist yet, initialize it
    if(this.linkOverlay[linkInteractionId] === undefined && anchor) {
      this.linkOverlay[linkInteractionId] = anchor
      return anchor
    }
    return this.linkOverlay[linkInteractionId]
  }

  setLinkOverlay(linkInteractionId: string, anchor: interactionAnchor) {
    this.linkOverlay[linkInteractionId] = anchor
  }

  deleteLinkOverlay(linkId: string) {
    if(this.linkOverlay[linkId]) delete this.linkOverlay[linkId]
  }

}


const exportPodStore = new podStore()
export default exportPodStore
