import React from 'react'
import { useStore } from '../../contexts/store'
import { useLocation } from "wouter";

import { observer } from 'mobx-react-lite'
import alertStore, { alert } from '../../stores/alertStore'

const PodImpro = () => {
  const { sessionStore, podStore, opStore } = useStore()
  const { session } = sessionStore
  const [location, navigate] = useLocation()
  
  window.Stores = useStore() || {}

  const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
   
  function niceBytes(x){
  
    let l = 0, n = parseInt(x, 10) || 0;
  
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }
  
  const makeLink = () => {
    const pod = podStore.pod
    if (pod.podId) {
      const srcInteractionId = sessionStore.createUuid()
      const dstInteractionId = sessionStore.createUuid()
      const linkId = sessionStore.createUuid()
      const nodeId = 'n-c5984f-dcb6-4c64-90f2-889655f29521'
      opStore.doOp({
        op: 'addLink',
        podId: pod.podId,
        data: {
            coid: null,
            interactionId: srcInteractionId,
            userId: session.user.uid,
            userName: session.user.userName,
            linkId: linkId,
            linkType: 'related',
            which: 'src',
            other: dstInteractionId,
            anchor: {
                nodeId: nodeId,
                relText: '',
                rects: [],
            },
            style: {},
            label: 'linkes label',
            tCreated: Math.floor(Date.now()/1000),
            tModified: Math.floor(Date.now()/1000),
        }    
      })
      
      opStore.doOp({
        op: 'addLink',
        podId: pod.podId,
        data: {
            coid: null,
            interactionId: dstInteractionId,
            userId: session.user.uid,
            userName: session.user.userName,
            linkId: linkId,
            linkType: 'related',
            which: 'dst',
            other: srcInteractionId,
            anchor: {
              nodeId: nodeId,
              relText: '',
              rects: [],
            },
            style: {},
            label: 'rechtes label',
            tCreated: Math.floor(Date.now()/1000),
            tModified: Math.floor(Date.now()/1000),
        }    
      })

      // navigate to the link
      const path = `pod/${pod.podId}/pdf/${nodeId}/editLink/${srcInteractionId}`
      navigate(path)
    }
  }
  
  const podStats = (pod) => {
    if (podStore.podCondition[pod.podId]?.status === 'broken') return null
    return <div>
      Contains&nbsp;
        {pod.getPdfFiles().length} files and&nbsp;
        {pod.getPdfFiles().map(f => f.annotations ? Object.keys(f.annotations).length : 0).reduce((acc, f) => acc+=f, 0)} Annotations,&nbsp;
        {pod.getPdfFiles().map(f => f.comments ? Object.keys(f.comments).length : 0).reduce((acc, f) => acc+=f, 0)} Comments,&nbsp;
        {pod.getPdfFiles().map(f => f.links ? Object.keys(f.links).length : 0).reduce((acc, f) => acc+=f/2, 0)} Links,&nbsp;
        {pod.getPdfFiles().map(f => f.weblinks ? Object.keys(f.weblinks).length : 0).reduce((acc, f) => acc+=f, 0)} Weblinks.
        Weighs in at { niceBytes(JSON.stringify({...pod}).length) }. The fingerprint is: <pre style={{backgroundColor:'#fff', 'border': '1px solid #000', display:'inline-block', padding:'0 4px'}}>{ podStore.pod.fingerprint() }</pre>
    </div>
  }

  const loadbuttons = (pod) => {
    if (podStore.podCondition[pod.podId]?.status !== 'broken') {
      return <span key={pod.podId}>
        <button key='load' onClick={async() => {
          const result = await podStore.loadPod(pod.podId)
          if (!result) alertStore.push(alert(`Pod ${pod.podId} could not be loaded.`, 'error'))
        }} title='Load this pod and make it active'>
          [ Call podLoad({pod.podId}) ]
        </button>
        &nbsp;
      </span>
    } else {

      return <span key={pod.podId}>
        <button key='load' onClick={async() => {
          const result = await podStore.loadPod(pod.podId)
          if (!result) alertStore.push(alert(`Pod ${pod.podId} could not be loaded.`, 'error'))
        }} title={`${podStore.podCondition[pod.podId]?.info}`}>
          [ <s>Call podLoad({pod.podId})</s> ]
        </button>
        <span>&nbsp;</span>
        <button onClick={async() => {
          await podStore.resetPod(pod.podId)
        }}>Reset</button>
        &nbsp;
      </span>
    }
  }

  let currentPodInfo = '---'
  //if ((podStore.pod?.podId) && (podStore.podCondition[podStore.pod?.podId]?.status !== 'broken')) {
  if ((podStore.pod?.podId)) {
    const podId = podStore.pod.podId
    currentPodInfo  = `${podId}`
    currentPodInfo += ' ('
    currentPodInfo += `${podStore.podCondition[podId]?.status}`
    if (podStore.pod.status === 'loading') currentPodInfo += ` ${podStore.pod.loadStatus}%`
    currentPodInfo += ')'
  }

  return <div style={{border:'1px solid #00a', backgroundColor:'#e4e4ff', padding:4}}>
    { podStore.pod?.podId ? <div><button onClick={() => { opStore.syncFull() }}>Call syncFull()</button></div> : null }
    { session.pods.map((pod) => loadbuttons(pod)) }
    <h1>Currently loaded: {currentPodInfo}</h1>
    { podStore.pod?.podId ? podStats(podStore.pod) : null }
  </div>
}

export default observer(PodImpro)