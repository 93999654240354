import { useLocation } from "wouter"
import { Box, Button } from "@mui/material"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const InteractionPanelLink = ({linkId, isActive, podId, pdfId}: {linkId: string, isActive: boolean, podId: string, pdfId: string}) => {
  const [location, navigate] = useLocation()
  const { podStore } = useStore()

  const link = podStore.getLink(pdfId, linkId)

  // only show content if the interaction was selected in the interactionPanel
  if(link === null || !isActive) return null
  const label = link.src.interactionId === linkId ? link.src.label : link.dst.label

  function getFollowLink () {
    if(link && link.src.interactionId === linkId) {
      return `/pod/${podId}/pdf/${link.dst.anchor.nodeId}/link/${link.dst.interactionId}`
    }
    if(link && link.dst.interactionId === linkId) {
      return `/pod/${podId}/pdf/${link.src.anchor.nodeId}/link/${link.src.interactionId}`
    }
    return `/pod/${podId}/pdf/${pdfId}`
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "min-content min-content"}}>
      <Box sx={{padding: "15px", margin: "0 5px 5px 0", color: "#000556", fontWeight: "300", fontSize: "17px"}}>
        {label}
      </Box>
      <Box sx={{display: "grid", gridTemplateColumns: "1fr", justifyContent: "end", gridGap: "10px", p: "10px 5px 5px 5px"}}>
        <Button onClick={(e:any) => { e.stopPropagation(); navigate(getFollowLink()) }} variant="contained" size="small">Follow Link</Button>
      </Box>
    </Box>
  )
}

export default observer(InteractionPanelLink)