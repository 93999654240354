import { Box, FormControl, MenuItem, Select } from "@mui/material"
import { iLink } from "../../../../../types/Interaction"
import { PdfFile } from "../../../../../types/Content"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const FileSelector = ({files, link}: {files: PdfFile[] ,link: iLink}) => {
  const { podStore, pdfMetaStore } = useStore()
  const fileId = podStore.getLinkFile(link.interactionId, link.anchor.nodeId)

  function handleFileChange(fileSelection: string) {
    if(fileSelection !== fileId) {
      podStore.setLinkFile(link.interactionId, fileSelection)
      podStore.setLinkOverlay(link.interactionId, {rects: [], relText: "", nodeId: fileSelection})
      pdfMetaStore.setLoaded(link.interactionId, false)
    }
  }

  if(files.length < 1) return null
  const fileItems = files.map(file =>
    <MenuItem
      onClick={() => handleFileChange(file.nodeId)}
      key={file.name}
      value={file.nodeId}
    >{file.name}</MenuItem>
  )
  return(
    <Box sx={{ m: "5px 25px" }}>
      <FormControl fullWidth sx={{ backgroundColor: "whitesmoke" }} size="small">
        <Select
          value={fileId}
        >
          {fileItems}
        </Select>
      </FormControl>
    </Box>
  )
}

export default observer(FileSelector)