import { makeAutoObservable } from "mobx"

export interface pdfMetaStoreModel {
    initPdfMeta(id: string, pdfUrl: string, thumbnailUrl: string): void,
    isInitialized(id: string): boolean,
    getIsCached(id: string): boolean,
    getNumPage: (id: string) => number | null,
    getLoaded: (id: string) => boolean | null,
    getPagePos: (id: string) => number[] | null,
    getPageHeights: (id: string) => number[] | null,
    getPageWidths: (id: string) => number[] | null,
    getScale: (id: string) => number,
    getScaleSelection: (id: string) => number | string,
    setIsCached(id: string, isCached: boolean): void,
    setNumPages(id: string, numPages: number): void,
    setLoaded(id: string, loaded: boolean): void,
    setPageHeights(id: string, pageHeights: number[]): void,
    setPagePos(id: string, pagePos: number[]): void,
    setPageWidths(id: string, pageWidths: number[]): void,
    setScale(id: string, scale: number): void
    setScaleSelection(id: string, selection: number | string): void
}

type PdfMeta = {
  id: string,
  isCached: boolean,
  loaded: boolean,
  numPages: number,
  pageHeights: number[],
  pagePos: number[],
  pageWidths: number[],
  pdfUrl: string,
  scale: number,
  scaleSelection: number | string,
  thumbnailUrl: string
}


class pdfMetaStore {
    pdfMetaList: PdfMeta[] = []

    constructor() {
      makeAutoObservable(this)
    }

    getPdfMeta(id: string) {
      if(id) {
        const pdfMeta = this.pdfMetaList.find(meta => meta.id === id)
        if(pdfMeta) return pdfMeta
      }
      return null
    }

    isInitialized(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return true
      else return false
    }

    initPdfMeta(id: string, pdfUrl: string, thumbnailUrl: string) {
      // check if meta already exists
      if(this.isInitialized(id)) {
        console.warn("PdfMeta already exists")
        return
      }
      // init pdfMeta with default values
      const pdfMeta: PdfMeta = {
        id: id,
        isCached: false,
        numPages: 0,
        loaded: false,
        pagePos: [0, 0],
        pageHeights: [],
        pageWidths: [],
        pdfUrl: pdfUrl,
        scale: 1,
        scaleSelection: 1,
        thumbnailUrl: thumbnailUrl
      }
      this.pdfMetaList.push(pdfMeta)
    }

    getIsCached(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.isCached
      else return false
    }

    getNumPage(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.numPages
      else return null
    }

    getLoaded(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.loaded
      else return null
    }

    getPagePos(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pagePos
      else return null
    }

    getPageHeights(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pageHeights
      else return null
    }

    getPageWidths(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pageWidths
      else return null
    }

    getScale(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.scale
      else return 1
    }

    getScaleSelection(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.scaleSelection
      else return 1
    }

    setIsCached(id: string, isCached: boolean) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.isCached = isCached
    }

    setNumPages(id: string, numPages: number) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.numPages = numPages
    }

    setLoaded(id: string, loaded: boolean) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.loaded = loaded
    }

    setPagePos(id: string, pagePos: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pagePos = pagePos
    }

    setPageHeights(id: string, pageHeights: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pageHeights = pageHeights
    }

    setPageWidths(id: string, pageWidths: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pageWidths = pageWidths
    }

    setScale(id: string, scale: number) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.scale = scale
    }

    setScaleSelection(id: string, selection: number | string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.scaleSelection = selection
    }

}


const exportPdfMetaStore = new pdfMetaStore()
export default exportPdfMetaStore
