import { useEffect } from "react"
import { useLocation } from "wouter"
import { useStore } from "../../contexts/store"
import { useParams } from "../../stores/uiStore"

const PdfClickEvents = ({
    storeId
  }: {
    storeId: string
  }) => {
  const [location, navigate] = useLocation()
  const { pdfMetaStore, podStore } = useStore()
  const { podId, pdfId } = useParams()
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`

  useEffect(() => {
    let start: number, end: number

    const getPageNumber = (y: number, pagePos: number[]) => {
      // get position of pdf viewer component
      const pdfViewer = document.getElementById(`${storeId}-pdf-viewer`)?.getBoundingClientRect()
      if(pdfViewer) {
        // y position within the pdf viewer component
        const yViewerPosition = (y - pdfViewer.y)
        // y position in relation to the height of the pdf viewer component
        const yRelativePosition = yViewerPosition / pdfViewer.height
        // calculate at which position the selection was made within the lower and upper page position of the considered pdf section
        const yPagePos = pagePos[0] + ((pagePos[1] - pagePos[0]) * yRelativePosition)
        // page on which the selection was made
        const pageNumber = Math.floor(yPagePos)
        return pageNumber
      }
      return null
    }

    const handleClick = (event: any) => {
      // how long was the duration of the click
      const clickDuration = (end - start)
      // get selection from mouse movement
      let sel = null
      if (window) sel = window.getSelection()
      // bottom and top page position of the viewed pdf section
      const pagePos = pdfMetaStore.getPagePos(storeId)

      if(podId && pdfId && pagePos) {

        if(clickDuration > 500) {
          // TODO: add selection code here
        }
        else {
          // click position
          const clickX = event.clientX
          const clickY = event.clientY
          // page on which the selection was made
          const pageNumber = getPageNumber(clickY, pagePos)
          // determine selected pdf page, calculate coordinates within the pdf page
          const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[0]?.children[0].getBoundingClientRect()

          if(pageNumber !== null && pageElement) {
            const currentScale = pdfMetaStore.getScale(storeId)
            // x position in relation to page element
            let x = clickX - pageElement.x
            // normalized x position
            x = parseToFixed(x / currentScale)
            // y position in relation to page element
            let y = clickY - pageElement.y
            // normalized y position
            y = parseToFixed(y / currentScale)

            const selectedInteraction = podStore.getInteractionByCoordinates(pdfId, x, y, pageNumber+1)
            if(selectedInteraction) {
              const selectedInteractionId = selectedInteraction.interaction.interactionId
              // TODO: check if already on desired destination subroute
              // TODO: scroll interactionPanel to selected interaction
              const interactionType = selectedInteraction.type
              if(interactionType === "annotation") navigate(`${pdfUrl}/annotation/${selectedInteractionId}`, {replace: true})
              if(interactionType === "comment") navigate(`${pdfUrl}/comment/${selectedInteractionId}`, {replace: true})
              if(interactionType === "link") navigate(`${pdfUrl}/link/${selectedInteractionId}`, {replace: true})
              if(interactionType === "tagging") navigate(`${pdfUrl}/tag/${selectedInteractionId}`, {replace: true})
            }
            else {
              // deselect interaction
              // TODO: check if interaction is selected at all
              navigate(`${pdfUrl}`, {replace: true})
            }
          }

        }
       }
    }


    const handleMouseDown = () => {
      start = Date.now()
    }

    const handleMouseUp = () => {
      end = Date.now()
    }

    // register event listener on mount
    document.getElementById(`${storeId}-pdf-viewer`)?.addEventListener('click', (event) => handleClick(event))
    document.getElementById(`${storeId}-pdf-viewer`)?.addEventListener('mousedown', handleMouseDown)
    document.getElementById(`${storeId}-pdf-viewer`)?.addEventListener('mouseup', handleMouseUp)

    return () => {
      // unregister event listener on unmount
      document.getElementById(`${storeId}-pdf-viewer`)?.removeEventListener('click', (event) => handleClick(event))
      document.getElementById(`${storeId}-pdf-viewer`)?.removeEventListener('mousedown', handleMouseDown)
      document.getElementById(`${storeId}-pdf-viewer`)?.removeEventListener('mouseup', handleMouseUp)
    }
  }, [storeId])

  return null
}

export default PdfClickEvents


function parseToFixed(number: number) {
  return parseFloat(number.toFixed(3))
}