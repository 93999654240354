import React, {useEffect} from 'react'
import { Route, Switch, useLocation } from 'wouter'
import { observer } from 'mobx-react-lite'
import { useStore } from '../contexts/store'
import alertStore, { alert } from '../stores/alertStore'
import { useTranslation } from 'react-i18next'

import Alerts from './Alerts'
import NoPage from "./NoPage"
import Header from './Header'
import Home from "./Pages/Home"
import Pod from "./Pages/Pod"
import PodProvider from './PodProvider'
import Pdf from './Pages/Pdf'
import uiStore from '../stores/uiStore'
import CommentView from './Pdf/CommentView'
import EditLink from './Pdf/EditLink/EditLink'
import System from './Dev/System'
import InteractionModal from './Pdf/InteractionModal/InteractionModal'
import Login from './Login'

import ErrorBoundaryComponent from './ErrorBoundaryComponent'

const App: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore, podStore, opStore, broadcastStore } = useStore()
  const [ location, navigate ] = useLocation()
  const session = sessionStore.session

  useEffect(() => {

    // onMount: add the sync interval
    const syncInterval = setInterval(() => {
      if (uiStore.showVerboseLogging.sync) console.log('trigger Sync')
      opStore.syncFull()
    }, 6000);

    // onMount: add the broadcast channel
    broadcastStore.createBroadcastChannel()

    // onMount: look for the loggedOut parameter
    const url = new URL(document.location.href) //new URL(`${baseUrl}/${location}`)
    const loggedOut = url.searchParams.get("loggedOut")
    if(loggedOut) {
      alertStore.push(alert(t('You have successfully logged out'), 'success'))
      broadcastStore.sendMessage({op: "logout"})
      return navigate(url.origin, { replace: true })
    }

    // onUnmount: remove the broadcast channel and end the sync interval
    return () => {
      broadcastStore.removeBroadcastChannel()
      clearInterval(syncInterval)
    };
  }, [broadcastStore])

  // Force Login
  if (!session) return <>
    <Alerts />
    <Login />
  </>

  return (
    <ErrorBoundaryComponent>
      <Header />
      <Alerts />
      <InteractionModal />
      <Switch>
        <Route path='/'><Home /></Route>
        <Route path='/system'><System /></Route>
        <Route path='/pod/:podId/pdf/:nodeId/editLink/:linkingId*'>{(params) => <PodProvider podId={params.podId} ><EditLink /></PodProvider>}</Route>
        <Route path='/pod/:podId/pdf/:nodeId*'>{(params) => <PodProvider podId={params.podId} ><Pdf pdfId={params.nodeId?.replace(/\/.*/, '')} /></PodProvider>}</Route>
        <Route path='/pod/:podId'>{(params) => <PodProvider podId={params.podId} ><Pod /></PodProvider>}</Route>
      </Switch>
    </ErrorBoundaryComponent>
  );
}

export default observer(App)