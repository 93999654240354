import { Box } from "@mui/material"
import { interactionAnchor } from "../../../../../types/Interaction"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const LinkLabel = ({
  anchor,
  borderColor,
  linkInteractionId,
  labelText
}: {
  anchor: interactionAnchor,
  borderColor: string,
  linkInteractionId: string,
  labelText: string
}) => {
  const { podStore } = useStore()
  const overlay = podStore.getLinkOverlay(linkInteractionId, anchor)
  const overlayRelText = overlay.relText

  return (
    <Box sx={{overflow: "hidden", margin: "10px 15px"}}>
      <Box sx={{color: "grey", fontSize: "14px"}}>{labelText}</Box>
      <Box sx={{
        backgroundColor: "#6c6caf14",
        border: "10px solid transparent",
        borderLeft: `10px solid ${borderColor}`,
        borderRadius: "5px",
        color: "#3d3d79",
        cursor: "default",
        minHeight: '100px',
        maxHeight: "100px",
        overflow: "hidden",
        padding: "0 5px 0 15px",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
      }}>
        {overlayRelText}
      </Box>
    </Box>
  )
}

export default observer(LinkLabel)