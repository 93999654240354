import { observer } from "mobx-react-lite"

const InteractionPanelComment = ({commentId, isActive}: {commentId: string, isActive: boolean}) => {
  // only show content if the interaction was selected in the interactionPanel
  if(!isActive) return null

  return (
    <>Comment</>
  )
}

export default observer(InteractionPanelComment)