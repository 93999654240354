import React from "react"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useStore } from "../../../contexts/store"

import CommentIcon from '@mui/icons-material/Comment'
import LinkIcon from '@mui/icons-material/Link'
import TagIcon from '@mui/icons-material/Sell'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText'
import { observer } from "mobx-react-lite"
import { OpCode } from "../../../../../types/OpCodes"

const InteractionSelector: React.FC = () => {
  const { uiStore } = useStore()
  const selectedInteraction = uiStore.getSelectedInteraction()

  const handleInteractionChange = (
    _event: React.MouseEvent<HTMLElement>,
    selectedInteraction: OpCode,
  ) => {
    // at least one button must be active
    if(selectedInteraction !== null) {
      // update component
      uiStore.setSelectedInteraction(selectedInteraction)
    }
  }

  return (
    <Box sx={{ backgroundColor: "whitesmoke", display: "grid", width: "50px" }}>
      <ToggleButtonGroup
        aria-label="select interaction type"
        color="secondary"
        exclusive
        onChange={handleInteractionChange}
        orientation="vertical"
        value={selectedInteraction}
      >
        <ToggleButton value="addAnnotation" aria-label="add annotation">
          <FormatColorTextIcon />
        </ToggleButton>
        <ToggleButton value="addComment" aria-label="add comment">
          <CommentIcon />
        </ToggleButton>
        <ToggleButton value="addLink" aria-label="add link">
          <LinkIcon />
        </ToggleButton>
        <ToggleButton value="addTagging" aria-label="add tag">
          <TagIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default observer(InteractionSelector)
