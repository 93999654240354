import React, { createRef, useEffect } from 'react'
// components
import { baseUrl } from '../../api/api'
import Toolbar from '../Pdf/Toolbar'
import Thumbnails from '../Pdf/Thumbnails'
import Viewer from '../Pdf/Viewer'
// mui
import { styled } from '@mui/system'
import InteractionPanel from '../Pdf/InteractionPanel/InteractionPanel'
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import PdfClickEvents from '../Pdf/PdfClickEvents'
import ScrollPdfViewer from '../Pdf/ScrollPdfViewer'
import ErrorBoundaryComponent from '../ErrorBoundaryComponent'

const Pdf = ({pdfId}: {pdfId:string|undefined}) => {
  const { podStore, uiStore } = useStore()
  const podId = podStore.pod.podId

  if (!pdfId) return null
  
  const linkEditId = podStore.activeLinkEditId

  const pdfUrl = `${baseUrl}/pdf/${pdfId}`
  const thumbnailUrl = `${baseUrl}/pageView/${pdfId}`
  const pageListRef: any = createRef()


  if(podId === null || pdfId === null) return <div>Could not render the pdf file "{podId}/{pdfId}".</div>

  return <ErrorBoundaryComponent>
    <PdfContainerStyle>
      <Toolbar
        pageListRef={pageListRef}
        storeId={pdfUrl}
      />
      <InteractionPanel
        pageListRef={pageListRef}
      />
      <ErrorBoundaryComponent context="Pdf>Viewer">
        <Viewer
          linkEditId={linkEditId}
          pageListRef={pageListRef}
          pdfId={pdfId}
          pdfUrl={pdfUrl}
          podId={podId}
          storeId={pdfUrl}
          thumbnailUrl={thumbnailUrl}
        />
      </ErrorBoundaryComponent>          
      
      <PdfClickEvents storeId={pdfUrl} />
      <ScrollPdfViewer storeId={pdfUrl} pageListRef={pageListRef} />
    </PdfContainerStyle>
  </ErrorBoundaryComponent>
}

export default observer(Pdf)


// style components -------------------------------------------------

const PdfContainerStyle = styled('div')({
  display: "grid",
  //gridTemplateColumns: "170px 300px auto 200px min-content",
  gridTemplateColumns: "70px 200px auto",
  overflowY: "auto",
})
