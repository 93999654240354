import { Link } from 'wouter'
import { PodClass } from '../../classes/Pod'
import { Folder as FolderType, PdfFile } from '../../../../types/Content'

import { Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import FolderIcon from '@mui/icons-material/FolderOpen'
import { observer } from 'mobx-react-lite'


const Contents = ({pod}: {pod: PodClass}) => {
  // generates view of folders and files
  const PodPanel = () => {
    // function: return files inside a folder
    const fileListing = (folderId: string) => {
      return pod.getPdfFiles().filter((file: PdfFile) => file.folderId === folderId).sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((file: PdfFile) => {
        return (
          <li key={file.nodeId}>
            <PdfFile pdfFile={file} />
          </li>
        )
      })
    }
    // data tree
    const elements: object[] = []
    // files without folder
    pod.getPdfFiles().filter((file: PdfFile) => !file.folderId).forEach((file: PdfFile) => {
      elements.push({
        weight: file.weight,
        obj: (
          <li key={file.nodeId}>
            <PdfFile pdfFile={file} />
          </li>
        )
      })
    })
    // folder with files inside
    pod.getFolders().forEach((folder: FolderType) => {
      elements.push({
        weight: folder.weight,
        obj: (
          <li key={folder.folderId}>
              <Folder folder={folder} fileListing={fileListing} />
          </li>
        )
      })
    })
    return (
      <ul style={{listStyleType: "none", padding: 0,  maxWidth: "1000px" }}>
          {elements.sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((element: any) => element.obj)}
      </ul>
    )
  }

  const PdfFile = ({pdfFile}: {pdfFile: PdfFile}) => (
    <Link to={`/pod/${pod.podId}/pdf/${pdfFile.nodeId}`} style={{textDecoration: "none"}}>
      <Paper elevation={2} sx={{height: "50px", display: "grid", gridTemplateColumns: "min-content auto", gridGap: "10px", alignContent: "center", p: 2, mt: 1, mb: 1, "&:hover": {backgroundColor: "whitesmoke"} }}>
        <TextSnippetIcon />
        {pdfFile.name}
      </Paper>
    </Link>
  )

  const Folder = ({folder, fileListing}: {folder: FolderType, fileListing: (folderId: string) => JSX.Element[] }) => (
    <ul style={{margin: "5px 0", listStyleType: "none", padding: 0}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
        >
          <FolderIcon sx={{mr: 1}} />
          {folder.name}
        </AccordionSummary>
          <AccordionDetails>
            {fileListing(folder.folderId)}
          </AccordionDetails>
      </Accordion>
    </ul>
  )

  return <PodPanel />
}

export default observer(Contents)