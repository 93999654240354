import { ChangeEvent, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Link } from "wouter"
import { useStore } from "../../contexts/store"
import { useTranslation } from "react-i18next"
import { iAnnotation } from "../../../../types/Interaction"
import { Box, Button, Card, CardActions, CardContent, CardHeader, TextField, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { OpCode } from "../../../../types/OpCodes"


//const Note: React.FC<{podId:string, pdfId:string, annotationId:string}> = (podId:string, pdfId:string, annotationId:string) => {
const Note = ({podId, pdfId, annotationId} : {podId:string, pdfId:string, annotationId:string}) => {
  const { t } = useTranslation()
  const { opStore, podStore } = useStore()

  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`
  const [label, setLabel] = useState("")
  const [isEdited, setIsEdited] = useState(false)
  const [interaction, setInteraction] = useState<{object: iAnnotation, type: string} | null>()

  // on mount
  useEffect(() => {
      if(pdfId && annotationId) {
        const interaction = podStore.getInteraction(pdfId, annotationId)
        // set interaction object for component
        if(interaction) setInteraction(interaction)
        // set interaction label
        if(interaction?.object?.label) setLabel(interaction?.object?.label)
        else setLabel("")

        setIsEdited(false)
      }
  }, [podStore, pdfId, annotationId, interaction?.object.label])

  // prevent errors if there is no pdf id
  if(pdfId === undefined || annotationId === undefined) return null

  function handleTextareaChange(e: ChangeEvent<HTMLInputElement>) {
    setLabel(e.target.value)
  }

  function submitChange() {
    let op = null
    if(interaction?.type === "annotation") op = "editAnnotation" as OpCode
    else {
      console.warn(`type ${interaction?.type} is not support in note`)
    }

    if(op && podId && annotationId) {
      opStore.doOp({
        op: op,
        podId: podId,
        usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
        data: {
          interactionId: annotationId,
          mods: {
            label: label
          }
        }
      })
    }
    // change button to show edit
    setIsEdited(!isEdited)
  }

  return (
    <Box sx={{
        alignContent: "start",
        display: "grid",
        m: "20px",
        gridGap: "30px",
        width: "250px"
      }}>
      <Card sx={{ minWidth: 200, minHeight: 200, borderLeft: `8px ${interaction?.object?.style.color} solid`, backgroundColor: "#ffea77" }}>
        <CardHeader
          action={
            <Link to={pdfUrl} >
              <IconButton aria-label="settings" >
                <CloseIcon />
              </IconButton>
            </Link>
          }
          subheader={t('Notes')}
        />
        <CardContent>
          {isEdited && (
            <>
              <TextField
                id="outlined-multiline-flexible"
                minRows={2}
                multiline
                onChange={handleTextareaChange}
                value={label}
              />
            </>
          )}
          {!isEdited && (
            <>
              <Typography sx={{fontSize: "1rem", lineHeight: "1.4375em", letterSpacing: "0.00938em", p: "16.5px 14px", minHeight: 80, lineBreak: "anywhere", whiteSpace: "pre-wrap"}}>
                {label}
              </Typography>
            </>
          )}
        </CardContent>
        <CardActions sx={{justifyContent: "end"}}>
          {isEdited && (
            <Button size="large" onClick={submitChange}>{t('Save')}</Button>
          )}
          {!isEdited && (
            <Button size="large" onClick={() => setIsEdited(!isEdited)}>{t('Edit')}</Button>
          )}
        </CardActions>
      </Card>
    </Box>
  )
}

export default observer(Note)