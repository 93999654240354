import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import Viewer from "../Viewer"

const LinkEditViewer = ({
  baseUrl,
  interactionId,
  pageListRef,
  pdfId,
  podId
}:{
  baseUrl: string,
  interactionId: string,
  pageListRef: any,
  pdfId: string,
  podId: string
}) => {
  const { podStore } = useStore()
  const fileId = podStore.getLinkFile(interactionId, pdfId)
  const pdfUrl = `${baseUrl}/pdf/${fileId}`
  const thumbnailUrl = `${baseUrl}/pageView/${fileId}`

  return (
    <Viewer
      linkEditId={interactionId}
      pageListRef={pageListRef}
      pdfId={fileId}
      pdfUrl={pdfUrl}
      podId={podId}
      storeId={interactionId}
      thumbnailUrl={thumbnailUrl}
    />
  )
}

export default observer(LinkEditViewer)