import { createRef } from "react"
import { useStore } from "../../../contexts/store"
import { useTranslation } from "react-i18next"
import { baseUrl } from "../../../api/api"
import { Box, Button, IconButton } from "@mui/material"
import LinkIcon from '@mui/icons-material/Link'
import CloseIcon from '@mui/icons-material/Close'
import ScaleSelector from "../Toolbar/ScaleSelector"
import { Link, useLocation } from "wouter"
import { observer } from "mobx-react-lite"
import LinkTextarea from "./EditLinkTextarea"
import FileSelector from "./EditLinkFileSelector"
import EditLinkRelText from "./EditLinkRelText"
import EditLinkViewer from "./EditLinkViewer"
import { useParams } from "../../../stores/uiStore"

const EditLink = () => {
  const { t } = useTranslation()
  const [location, navigate] = useLocation()
  const { opStore, podStore, uiStore } = useStore()

  const { podId, pdfId, interactionType, interactionId } = useParams()

  if (interactionType !== 'editLink') return <div>Not called with proper URL</div>
  const linkEditId = interactionId

  const srcListRef: any = createRef()
  const dstListRef: any = createRef()
  const files = podStore.pod.getPdfFiles()

  const link = podStore.getLink(pdfId, linkEditId)

  if(link === null || podId === null || pdfId === null) return null

  const linkColor = link?.src.style.color ? link?.src.style.color : uiStore.getOpColor("link")
  const interactionUrl = `/pod/${podId}/pdf/${pdfId}/link/${linkEditId}`


  const srcPdfId = link.src.anchor.nodeId
  const srcLinkAnchor = link.src.anchor
  const srcInteractionId = link.src.interactionId

  const dstPdfId = link.dst.anchor.nodeId
  const dstLinkAnchor = link.dst.anchor
  const dstInteractionId = link.dst.interactionId

  function handleSave() {
    // save source link edits
    const srcLabel = podStore.getLinkLabel(srcInteractionId, null)
    const srcAnchor = podStore.getLinkOverlay(srcInteractionId, null)
    if(podId && srcInteractionId) {
      opStore.doOp({
        op: "editLink",
        podId: podId,
        usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
        data: {
          interactionId: srcInteractionId,
          mods: {
            anchor: JSON.parse(JSON.stringify(srcAnchor)),
            label: srcLabel
          },
        }
      })
    }
    // save destination link edits
    const dstLabel = podStore.getLinkLabel(dstInteractionId, null)
    const dstAnchor = podStore.getLinkOverlay(dstInteractionId, null)
    if(podId && dstInteractionId) {
      opStore.doOp({
        op: "editLink",
        podId: podId,
        usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
        data: {
          interactionId: dstInteractionId,
          mods: {
            anchor: JSON.parse(JSON.stringify(dstAnchor)),
            label: dstLabel,
          }
        }
      })
    }
    // delete temporary edits
    deleteEdits()
    // redirect to link interaction
    navigate(interactionUrl)
  }

  function deleteEdits() {
    // reset edits for source link
    podStore.deleteLinkLabel(srcInteractionId)
    podStore.deleteLinkOverlay(srcInteractionId)
    podStore.deleteLinkFile(srcInteractionId)
    // reset edits for destination link
    podStore.deleteLinkLabel(dstInteractionId)
    podStore.deleteLinkOverlay(dstInteractionId)
    podStore.deleteLinkFile(dstInteractionId)
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "min-content auto min-content"}}>
      <Box sx={{
        borderBottom: "1px solid lightgrey",
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
      }}>
        {/* header left  */}
        <Box sx={{display: "grid", gridTemplateColumns: "min-content auto min-content", alignItems: "center", borderLeft: `15px solid ${linkColor}`, p: "5px 5px"}}>
          <LinkIcon />
          <FileSelector
            files={files}
            link={link.src}
          />
          <Box sx={{display: "grid", gridTemplateColumns: "min-content", justifyContent: "center"}}>
            <ScaleSelector pageListRef={srcListRef} storeId={srcInteractionId} />
          </Box>
        </Box>
        {/* header right  */}
        <Box sx={{display: "grid", gridTemplateColumns: "min-content auto min-content", alignItems: "center", p: "0 5px", borderLeft: "1px solid lightgrey"}}>
          <Box sx={{display: "grid", gridTemplateColumns: "min-content", justifyContent: "center"}}>
            <ScaleSelector pageListRef={dstListRef} storeId={dstInteractionId} />
          </Box>
          <FileSelector
            files={files}
            link={link.dst}
          />
          <Link to={interactionUrl} style={{paddingRight: "10px"}}  onClick={deleteEdits}>
            <IconButton aria-label="settings" >
              <CloseIcon />
            </IconButton>
          </Link>
        </Box>
      </Box>
      <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
        {/* pdf viewer left */}
        <EditLinkViewer
          baseUrl={baseUrl}
          interactionId={srcInteractionId}
          pageListRef={srcListRef}
          pdfId={srcPdfId}
          podId={podId}
        />
        {/* pdf viewer right */}
        <EditLinkViewer
          baseUrl={baseUrl}
          interactionId={dstInteractionId}
          pageListRef={dstListRef}
          pdfId={dstPdfId}
          podId={podId}
        />
      </Box>
      {/* footer */}
      <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr", backgroundColor:"ghostwhite"}}>
        {/* Content footer left */}
        <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
          <EditLinkRelText
            anchor={srcLinkAnchor}
            borderColor={linkColor}
            labelText={t('Source Link')}
            linkInteractionId={srcInteractionId}
          />
          <LinkTextarea
            link={link.src}
          />
        </Box>
        {/* Content footer right */}
        <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr", borderLeft: "1px solid #afafaf"}}>
          <EditLinkRelText
            anchor={dstLinkAnchor}
            borderColor={linkColor}
            labelText={t('Destination Link')}
            linkInteractionId={dstInteractionId}
          />
          <Box sx={{display: "grid", gridTemplateRows: "auto min-content"}}>
            <LinkTextarea
              link={link.dst}
            />
            {/* footer buttons */}
            <Box sx={{justifySelf: "end", p: "0 15px 15px 0"}}>
              <Link to={interactionUrl} style={{marginRight: "15px"}}  onClick={deleteEdits}>
                <Button sx={{backgroundColor: "white"}} size="large" variant="outlined">{t('Cancel')}</Button>
              </Link>
              <Button size="large" variant="contained" onClick={handleSave}>{t('Save')}</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(EditLink)