import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useStore } from '../../../contexts/store'
import { Alert, Box, Button, TextField } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { iTag, interactionAnchor } from '../../../../../types/Interaction'

const filter = createFilterOptions<TagOptionType>()

interface TagOptionType {
  inputValue?: string
  name: string,
  tagId?: string
}

const EditTag = ({
    anchor,
    color,
    interaction,
    podId,
    session
  }:
  {
    anchor: interactionAnchor | null,
    color: string | null,
    interaction: iTag | undefined,
    podId: string,
    session: any
  }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<TagOptionType | null>(null)
  const [showWarning, setShowWarning] = useState(false)
  const { podStore, uiStore, opStore, sessionStore } = useStore()
  const tagPool: TagOptionType[] = podStore.getTagPool()

  useEffect(() => {
    // if tag is edited: load existing tag data
    if(interaction) {
      const tagProp = podStore.getTagProp(interaction.tagId)
      const name = tagProp?.name
      if(name) {
        setValue({inputValue: name, name: name, tagId: interaction.tagId})
      }
    }
  }, [podStore, interaction])

  if(anchor === null) return null

  const handleSave = () => {
    if(value && value.name) {
      const tagName = value.name
      let tagId = value.tagId

      // if tag does not exist: add tag to the pool of tags
      if(tagId === undefined) {
        tagId = sessionStore.createUuid()
        opStore.doOp({
          op: "addTag",
          podId: podId,
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          data: {
            tagId: tagId,
            name: tagName,
            description: "",
            coid: null,
            tCreated: Math.floor(Date.now()/1000),
            tModified: Math.floor(Date.now()/1000)
          }
        })
      }

      // save edited tagging
      if(interaction) {
        opStore.doOp({
          op: "editTagging",
          podId: podId,
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          data: {
            interactionId: interaction.interactionId,
            mods: {
              tagId: tagId
            }
          }
        })
      }
      // create new tagging
      else {
        opStore.doOp({
          op: "addTagging",
          podId: podId,
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          data: {
            interactionId: sessionStore.createUuid(),
            userId: session.user.uid,
            userName: session.user.name,
            anchor: {
              nodeId: anchor.nodeId,
              relText: anchor.relText,
              rects: JSON.parse(JSON.stringify(anchor.rects)),
            },
            style: { color: color },
            tagId: tagId,
            label: "",
            tCreated: Math.floor(Date.now()/1000),
            tModified: Math.floor(Date.now()/1000),
            lastModUuid: sessionStore.createUuid()
          }
        })
      }

      // close modal
      uiStore.closeInteractionModal()
      // delete selected anchor in store
      uiStore.setSelectedAnchor(null)
    }
    if(value === null || value.name === undefined) setShowWarning(true)
  }

  return (
    <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", padding: "24px 0"}}>
      <Autocomplete
        value={value}
        onChange={(_, newValue) => {
          // hide warning
          setShowWarning(false)
          if (typeof newValue === 'string') {
            setValue({
              name: newValue,
            })
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              name: newValue.inputValue,
            })
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `${t('Add')} "${inputValue}"`,
            });
          }

          return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="tag_selection"
        options={tagPool}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue
          }
          // Regular option
          return option.name
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{ width: 350 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Tag" />
        )}
      />
      <Button sx={{marginLeft: "15px"}} variant='contained' onClick={handleSave}>{t('Save')}</Button>
      {showWarning && (
        <Alert sx={{marginTop: "10px"}} severity="warning">{t('Please select tag')}</Alert>
      )}
    </Box>
  )
}

export default EditTag