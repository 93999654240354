import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppContainer from './components/AppContainer';
import * as serviceWorkerRegistration from './sw/serviceWorkerRegistration';

import CssBaseline from '@mui/material/CssBaseline';
import { StoreProvider } from './contexts/store'
import Loading from './components/Loading'

// initialize translation
import './i18n'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StoreProvider>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <AppContainer />
      </Suspense>
    </StoreProvider>
  </React.StrictMode>
);

// register service worker
serviceWorkerRegistration.register()