import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'
import podStore from '../../stores/podStore'
import api from '../../api/api';
import { Op } from '../../../../types/Ops';

const System: React.FC = () => {
  const pendingOps: Op[] = []
  const { broadcastStore, opStore } = useStore()
  const [ swFingerprint, setSwFingerprint ] = useState('')
  const [ swPendingOps, setSwPendingOps ] = useState(pendingOps)

  useEffect(() => {
    const fetchData = async () => {
      await getServiceWorkerPendingOps(broadcastStore.serviceWorkerStatus.syncQueueLength)
    }
    fetchData().catch(console.error);    
  })

  const clientQueueLength = opStore.static.queue.length ? opStore.static.queue.length : 0
  // const syncStatus = opStore.static.busy ? '*' : '.'
  var serviceWorkerPodFingerprint = '...'
  if (podStore.pod?.podId) {
    if (broadcastStore.serviceWorkerStatus.fingerprints) serviceWorkerPodFingerprint = broadcastStore.serviceWorkerStatus.fingerprints[podStore.pod.podId]
  }
  var localPodFingerprint = '...'
  if (podStore.pod?.podId) {
    localPodFingerprint = podStore.pod.fingerprint()
  }

  const style = {
    fontSize:9,
    color: opStore.static.busy ? '#f00' : '#fff',
    borderLeft: opStore.static.busy ?  '4px solid red' : '1px solid black',
  }

  const fingerprintStyle = (localPodFingerprint !== serviceWorkerPodFingerprint) ? { color: 'orange' } : {}

  const updateSwFingerprint = async () => {
    if (podStore.pod?.podId) {
      const res = await api.getPodFingerprint(podStore.pod.podId, false)
      if (res.status === 200) setSwFingerprint(res.data.replace())
    }
  }

  const getServiceWorkerPendingOps = async (n:number) => {
    if ((n) && (podStore.pod?.podId)) {
      const res = await api.getPendingOps((podStore.pod.podId))
      if (res) setSwPendingOps(res); else setSwPendingOps([])
    }
    return null
  }

  return <div>
    <h1>Pod {podStore.pod?.podId}</h1>
    <button disabled={!(podStore.pod?.podId)} onClick={async() => { if (podStore.pod?.podId) { const podId = podStore.pod?.podId; await podStore.resetPod(podId); podStore.loadPod(podId) } }} title={'Unloads the pod and triggers a reload from the backend'}>
      Reset Pod
    </button>
    <h2>Sync</h2>
    <pre>
      LastSyncOid: {podStore.pod?.lastSyncOid}
    </pre>
    <h2>Fingerprint</h2>
      <pre>
        Client:        {localPodFingerprint}<br />
        ServiceWorker: {serviceWorkerPodFingerprint}
      </pre>
    Unhashed Fingerprint:
    <table style={{fontSize:'11px', width:'100%'}}>
      <tbody>
        <tr>
          <th style={{width:'50%', borderBottom:'1px solid #000'}}>Client</th>
          <th style={{width:'50%', borderBottom:'1px solid #000'}}>Service Worker</th>
        </tr>
        <tr>
          <td style={{borderRight:'1px solid #000', borderBottom:'1px solid #000'}}><pre>{podStore.pod?.fingerprint(false)}</pre></td>
          <td style={{borderRight:'1px solid #000', borderBottom:'1px solid #000'}} onClick={updateSwFingerprint}>{ swFingerprint ? <pre>{swFingerprint}</pre> : 'Not yet available' }</td>
        </tr>
      </tbody>
    </table>
    <h2>{clientQueueLength} Pending Ops in Client</h2>
    <ol>
    {opStore.static.queue.map((row: any, i:number) => {
      return <li key={i}>{row.opLogId}: {row.op}</li>
    })}
    </ol>
    <h2>{broadcastStore.serviceWorkerStatus.syncQueueLength} Pending Ops in ServiceWorker</h2>
    <ol>
    { swPendingOps.map((row: any, i:number) => {
      return <li key={i}>{row.opLogId}: {row.op}</li>
    }) }
    </ol>
  </div>
}

export default observer(System)