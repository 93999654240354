import React from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../contexts/store'

import { useLocation } from 'wouter';
import { useTranslation } from 'react-i18next'

import { versions } from '../version';

import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { blueGrey } from '@mui/material/colors';

import SyncStatus from './Dev/SyncStatus';
import { useParams } from '../stores/uiStore';


const Header: React.FC = () => {
  const { podId, pdfId } = useParams()
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { uiStore, sessionStore, podStore } = useStore()
  const { t } = useTranslation()

  const { session } = sessionStore
  const version = versions[0].version
  const open = Boolean(anchorEl)

  const [ location, navigate ] = useLocation()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const HeaderMenuButton = (props: any) => <Tooltip title={props.title}><IconButton
  onClick={ (props.to ? () => { navigate(props.to) } : props.onClick) }
  size="large"
  edge="start"
  color="inherit"
  aria-label={props.description}
  sx={{ ml:1 }}
>
  {props.icon}
</IconButton></Tooltip>

  const WifiStatus = () => { return uiStore.isOffline ?
    <HeaderMenuButton icon={<WifiOffIcon sx={{opacity: 0.7, fontSize:"1.7rem"}} />} description={t('SHRIMP_PODS is offline')} /> :
    <HeaderMenuButton icon={<WifiIcon />} sx={{fontSize:"1.7rem"}} description={t('SHRIMP_PODS is online')} />
  }

  return <>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleClose}><ListItemIcon><AccountCircleIcon sx={{fontSize:"1.2rem"}}/></ListItemIcon><ListItemText>{t('Your User Profile')}</ListItemText></MenuItem>
      <MenuItem onClick={handleClose}><ListItemIcon><InfoIcon sx={{fontSize:"1.2rem"}} /></ListItemIcon><ListItemText>{t('About SHRIMP_PODS')} (v.{version})</ListItemText></MenuItem>
      <MenuItem onClick={() => { sessionStore.logout(); handleClose() }}><ListItemIcon><PowerSettingsNewIcon sx={{fontSize:"1.2rem", color: "#ff5552"}} /></ListItemIcon><ListItemText>{t('Logout')}</ListItemText></MenuItem>
    </Menu>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: blueGrey[900], boxShadow: "none" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, justifiyContent: "flex-start" }}>

            <HeaderMenuButton to="/" title={t('View all your Pods')} icon={<HomeIcon sx={{fontSize:"1.7rem", color: (podId ? "#ffffff50" : "#fff" )}} />} description="home" />

            {(podId) && (
              <HeaderMenuButton to={`/pod/${podId}`} title={podStore.pod?.name} icon={<FolderSpecialIcon sx={{fontSize:"1.6rem", color: (pdfId ? "#ffffff50" : "#fff" )}} />} description="pod" />
            )}

            {(pdfId) && (
              <HeaderMenuButton to={`/pod/${podId}/pdf/${pdfId}`} title={podStore.pod?.content.pdfFiles[pdfId]?.name} icon={<TextSnippetIcon sx={{fontSize:"1.6rem", cursor: "default"}} />} description="file" />
            )}
          </Box>
          
          <SyncStatus />
          
          <HeaderMenuButton icon={<NotificationsIcon sx={{fontSize:"1.7rem"}} />} description="notification" />
          <WifiStatus />
          <IconButton
            onClick={handleClick}
            size="large"
            edge="start"
            color="inherit"
            aria-label={'menu'}
            sx={{ ml:1 }}
            >
            <AccountCircleIcon sx={{fontSize:"1.7rem"}} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

export default observer(Header)