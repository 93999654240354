import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../contexts/store'
import { PodClass } from '../classes/Pod'
import Loading from './Loading'
import LoadingPod from './Pod/LoadingPod'

import { useTranslation } from 'react-i18next'
//import { useLocation } from 'wouter'


interface PodProps {
  podId: string
}

const PodProvider = (props: React.PropsWithChildren<PodProps>)  => {
  const { podId } = props
  const { t } = useTranslation()
  const { podStore, uiStore } = useStore()
  const pod: PodClass = podStore.pod
  // const [location] = useLocation()

  useEffect(() => {
    if ((pod === null) || (pod.podId !== podId)) {
      podStore.loadPod(podId)
    }    
  })

  // no data available
  if(pod === null) return <div>'pod' is null when trying to show pod {podId}</div>
  // wait until podId has been updated in store
  if(pod.podId !== podId) return <Loading />
  // pod is loading
  if(pod.status === "loading") return <LoadingPod pod={pod} />
  if (pod.status !== 'loaded') return <div>Pod is {pod.status}</div>
/*
  let parms:RegExpMatchArray | null = null
  let urlParams:{[key:string]:string} = {}
  parms = location.match(/^\/pod\/([^/]+)\/?(pdf\/([^/]+))?\/?(([a-z]+)\/([^/]+))?/)
  if (parms && parms[1]) uiStore.setUrlParams('podId', parms[1]); else uiStore.setUrlParams('podId', '')
  if (parms && parms[3]) uiStore.setUrlParams('nodeId', parms[3]); else uiStore.setUrlParams('nodeId', '')
  if (parms && parms[3]) uiStore.setUrlParams('pdfId', parms[3]); else uiStore.setUrlParams('pdfId', '')
  if (parms && parms[5]) uiStore.setUrlParams('interactionType', parms[5]); else uiStore.setUrlParams('interactionType', '')
  if (parms && parms[6]) uiStore.setUrlParams('interactionId', parms[6]); else uiStore.setUrlParams('interactionId', '')
//*/
  return <>{props.children}</>;
}


export default observer(PodProvider)