import React from 'react'
import { createPortal } from 'react-dom'
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import { Rect, iAnnotation, iComment, iLink, iTag } from '../../../../types/Interaction'
import { useParams } from '../../stores/uiStore'

const Overlay = (
    {
      linkEditId,
      pageId,
      pdfId,
      pageNumber,
      scale
    }: {
      linkEditId: string | null,
      pageId: string,
      pdfId: string,
      pageNumber: number,
      scale: number
    }
) => {
  //const { annotationId, commentId, linkId, tagId } = 

  const { podStore, uiStore } = useStore()

  const { interactionId } = useParams()
  // load annotations for actual page
  const annotations: iAnnotation[] | null = podStore.getAnnotations(pdfId, pageNumber)
  // load comments for actual page
  const comments: iComment[] | null = podStore.getComments(pdfId, pageNumber)
  // load links for actual page
  const links: iLink[] | null = podStore.getLinks(pdfId, pageNumber)
  // load tags for actual page
  const tags: iTag[] | null = podStore.getTags(pdfId, pageNumber)

  // test if page exists
  const pageContainer = document.getElementById(pageId)
  if(pageContainer === null || pageContainer.children[0] === null || pageContainer.children[0].children[0] === null) return null

  // get page inside pdf container
  const page = pageContainer.children[0].children[0]

  // save all associated rects for each interaction
  const overlayList: {rect: Rect, color: string, op: string, interactionId: string}[] = []

  // link edit view: show only the currently selected link overlay,
  // otherwise: show overlay of all interactions
  if(linkEditId) {
    const linkOverlay = podStore.getLinkOverlay(linkEditId, null)
    if(linkOverlay) {
      const rects = linkOverlay.rects
      if(Array.isArray(rects) && rects.length) {
        rects.forEach((rect: Rect) => {
          if(rect.p === pageNumber) {
            overlayList.push({rect: rect, color: "#00ced16b", op: "annotation", interactionId: linkEditId})
          }
        })
      }
    }
  } else {
    // read all associated rectangles for each annotation
    if(Array.isArray(annotations) && annotations.length) {
      annotations.forEach( annotation => {
        const color = annotation.style.color ? uiStore.getSemiTransparentColor(annotation.style.color) : uiStore.getSemiTransparentColor(uiStore.getOpColor("annotation"))
        const rects = annotation.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            overlayList.push({rect: rect, color: color, op: "annotation", interactionId: annotation.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each comment
    if(Array.isArray(comments) && comments.length) {
      comments.forEach( comment => {
        const color = comment.style.color ? comment.style.color : uiStore.getOpColor("comment")
        const rects = comment.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            overlayList.push({rect: rect, color: color, op: "comment", interactionId: comment.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each link
    if(Array.isArray(links) && links.length) {
      links.forEach( link => {
        const color = link.style?.color ? link.style.color : uiStore.getOpColor("link")
        const rects = link.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            overlayList.push({rect: rect, color: color, op: "link", interactionId: link.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each tag
    if(Array.isArray(tags) && tags.length) {
      tags.forEach( tag => {
        const color = tag.style?.color ? tag.style.color : uiStore.getOpColor("tagging")
        const rects = tag.anchor.rects
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            overlayList.push({rect: rect, color: color, op: "tagging", interactionId: tag.interactionId})
          })
        }
      })
    }
  }

  // prevent showing overlay if no interaction is set
  if (overlayList.length < 1) return null
  return (
    createPortal(
      overlayList.map((item: {rect: Rect, color: string, op: string, interactionId: string}, index: number) => {
        if(item.op === "annotation") {
          return (
            <div key={index} style={{
              backgroundColor: item.color,
              height: item.rect.h * scale,
              left: item.rect.x * scale,
              opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
              position: "absolute",
              top: item.rect.y * scale,
              width: item.rect.w * scale,
              zIndex: 1,
            }}></div>
          )
        }
        if(item.op === "comment") {
          return (
            <div key={index} style={{
              borderBottom: `2px solid ${item.color}`,
              height: item.rect.h * scale,
              left: item.rect.x * scale,
              opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
              position: "absolute",
              top: (item.rect.y * scale) + 2, // paddingBottom: 2px
              width: item.rect.w * scale,
              zIndex: 2,
            }}></div>
          )
        }
        if(item.op === "link") {
          return (
            <div key={index} style={{
              borderBottom: `2px solid ${item.color}`,
              height: item.rect.h * scale,
              left: item.rect.x * scale,
              opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
              position: "absolute",
              top: (item.rect.y * scale) + 2, // paddingBottom: 2px
              width: item.rect.w * scale,
              zIndex: 2,
            }}></div>
          )
        }
        if(item.op === "tagging") {
          return (
            <div key={index} style={{
              borderBottom: `2px solid ${item.color}`,
              height: item.rect.h * scale,
              left: item.rect.x * scale,
              opacity: (interactionId && item.interactionId !== interactionId) ? "20%" : "",
              position: "absolute",
              top: (item.rect.y * scale) + 2, // paddingBottom: 2px
              width: item.rect.w * scale,
              zIndex: 2,
            }}></div>
          )
        }
        return null
      })
      ,page
    )
  )
}

export default observer(Overlay)
