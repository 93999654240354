import i18next from 'i18next'
import uiStore from '../stores/uiStore'
import alertStore, { alert } from '../stores/alertStore'
import sessionStore from '../stores/sessionStore'
import { PodClass } from '../classes/Pod'
import { Op, idbStoredOp } from '../../../types/Ops'
import { syncPullRequest } from '../../../types/Pod'
import { versions } from '../version'
const version = versions[0]

export const baseUrl = process.env.NODE_ENV === "production" ? 'https://dev.shrimpp.de:5000' : 'http://localhost:5000'

const api = {
  /** redirect to server login route,
   *  wait for the callback from the server, which will set a cookie with the sessionId
   */
  login() {
    window.location.href = `${baseUrl}/login`
  },

  async reportError(error: Error, info: { componentStack: string }) {
    const sessionId = sessionStore.session ? sessionStore.session.sessionId : null
    return this.fetch(`${baseUrl}/reportError`, {
      method:'POST',
      headers: {
        'X-SHRIMP-ID': sessionId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: sessionStore.session ? sessionStore.session.user.uid : null,
        version: version.version,
        report: {
          error,
          info,
        }
      })
    })
  },

  async syncFull(pulling: Array<syncPullRequest>, pushing: Op[] | idbStoredOp[], sessionId: string = '') {
    if (sessionId === '') sessionId = sessionStore.session ? sessionStore.session.sessionId : ""
    const res = await this.fetch(`${baseUrl}/syncFull`, {
      method: 'POST',
      timeout: 8000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionId,
      },  
      body: JSON.stringify({
        pulling: pulling,
        pushing: pushing
      })
    })
    if (res) return res
    return undefined
  },

  async getPendingOps(podId: string) {
    const res = await api.fetch(`${baseUrl}/getPendingOps`, {
      method: 'POST',
      timeout: 8000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({podId})    
    })
    if (res) return res.body as Op[]
    return undefined
  },

  async getPodFingerprint(podId: string, hashed:boolean = true, fromBackend:boolean = false) {
    const res = await api.fetch(`${baseUrl}/getPodFingerprint`, {
      method: 'POST',
      timeout: 8000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({podId, hashed, fromBackend})    
    })
    if (res) return res.body
    return undefined
  },

  /** attempt to load a pod and receive either a fully populated pod or an empty
   *  init pod, which is then populated through ops during the chunked loading
   *  cycles
   */

  async loadPod(podId: string, reset: boolean = false) {
    const obj = await this.fetch(`${baseUrl}/loadPod`, { 
      method: 'POST',
      timeout: 8000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({ podId, reset })
    })

    if (obj) {
      const pod = new PodClass(obj.body.pod, true)
      return pod  
    }
    return undefined
  },

  /** load a server-determined number of operations that will populate an originally empty 
   *  init pod to the extent defined by initMaxCoid.
   */
  async loadPodChunk(podId: string, lastSyncOid: number, initMaxCoid: number) {
    const res = await this.fetch(`${baseUrl}/loadPodChunk`, { 
      method: 'POST',
      timeout: 8000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-SHRIMP-ID': sessionStore.session ? sessionStore.session.sessionId : "",
      },  
      body: JSON.stringify({ podId, lastSyncOid, initMaxCoid })
    })

    if (res) {
      const { ops, totalOps } = res.body
      return {
        ops: ops as Op[],
        totalOps: totalOps as number,
      }  
    }
    
    return undefined
  },

  /** request the service worker whether an active session exists and can be returned
   *  the cookie sent with the request is used for authentication,
   *  this cookie is deleted from the server after the request is made
   *  if no session available, login screen is displayed
  */
  async isSession() {
    // TODO: use typescript
    return new Promise<any | null>(async (resolve) => {
      try {
        const res = await fetch(`${baseUrl}/isSession`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
        })
        if(res.ok) {
          handleNetworkSuccess()
          const session = await res.json()
          if(session && session.sessionId) {
              resolve(session)
          }
        }
      } catch(err) {
        handleNetworkError(err)
      }
      resolve(null)
    })
  },
  /** test route: authenticate data request with sessionId stored in X-SHRIMP-Id header */
  async test() {
    // TODO: use typescript
    return new Promise<any | null>(async (resolve) => {
      try {
        const res = await fetch(`${baseUrl}/test`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'X-SHRIMP-Id': sessionStore.session ? sessionStore.session.sessionId : "",
            }
        })

        if(res.ok) {
            handleNetworkSuccess()
            const test = await res.json()
            //if(test) window.alert(JSON.stringify(test))
            resolve(test)
        }
      } catch(err) {
        handleNetworkError(err)
      }
      resolve(null)
    })
  },
  // TODO: use typescript
  async addOps(op: any) {
    return new Promise<boolean>(async (resolve) => {
      try {
        const res = await fetch(`${baseUrl}/addOps`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
              "Content-Type": "application/json",
              'X-SHRIMP-Id': sessionStore.session ? sessionStore.session.sessionId : "",
          },
          body: JSON.stringify({
              ops: op
          }),
        })

        if(res.ok) {
          handleNetworkSuccess()
          resolve(true)
        }
      } catch(err) {
        // TODO: remove operation if no service worker could save it
        handleNetworkError(err)
      }
      resolve(false)
    })
  },
  getCachedPdf() {
    return new Promise<string[] | null>(async (resolve) => {
      try {
        const res = await fetch(`${baseUrl}/getCachedPdf`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
              'X-SHRIMP-Id': sessionStore.session ? sessionStore.session.sessionId : "",
          }
        })

        if(res.ok) {
          handleNetworkSuccess()
          const cachedPdf = await res.json()
          resolve(cachedPdf)
        }
      } catch(err) {
        handleNetworkError(err)
      }
      resolve(null)
    })
  },
  /** redirect to the keycloak logout route,
   * use hidden input field to transfer the session id to server
   */
  logout() {
    // TODO: use typescript
    const form = document.createElement('form');
    form.method = 'POST'
    form.action = `${baseUrl}/logout`
    // create input field with sessionId
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = "sessionId"
    hiddenField.value = sessionStore.session ? sessionStore.session.sessionId : ""
    form.appendChild(hiddenField)
    // add input field to html
    document.body.appendChild(form)
    form.submit()
  },

  async fetch(url: string | Request, options: any) {
    const { timeout = 8000, pipeThrough = false, ...fetchOptions } = options;
    var response
    if (!fetchOptions.headers) fetchOptions.headers = {}
    fetchOptions.headers['X-SHRIMP-VERSION'] = `${version.version} ${version.key}`
    try {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);
    
      response = await fetch(url, {
        ...fetchOptions,
        signal: controller.signal  
      })

      clearTimeout(id)

      if (pipeThrough) return response as Response

      return {
        status: response.status,
        body: await response.json()
      }
    }
    catch(e) {
      console.warn(e, response)
      return undefined
    }
  },

}

const handleNetworkError = (err: unknown) => {
  if(navigator.serviceWorker && navigator.serviceWorker.controller) {
    uiStore.setIsOffline(true)
  } else {
    alertStore.push(alert(i18next.t('the changes could not be saved and will be lost at the next startup'), 'error', i18next.t('No Internet connection') ?? '', 'WifiOffIcon'))
  }
  console.warn("Network Error:",err)
}

const handleNetworkSuccess = () => {
  if(navigator.serviceWorker && navigator.serviceWorker.controller) {
    uiStore.setIsOffline(false)
  }
}

export default api