import React, { useState, memo, CSSProperties, useEffect } from 'react'
// components
import Error from '../LoadError'
// pdf
import { Document, Page, pdfjs } from 'react-pdf'
import { PDFDocumentProxy } from 'pdfjs-dist'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
// virtualization
import { VariableSizeList, FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
// mui
import { styled } from '@mui/system'
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import Overlay from './Overlay'

import { Rect as RectModel, interactionAnchor } from '../../../../types/Interaction'
import LoadingPdf from './LoadingPdf'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'


const Pdf = ({
    pageListRef,
    pdfId,
    pdfUrl,
    podId,
    linkEditId,
    storeId,
    thumbnailUrl
  }: {
    pageListRef: any,
    pdfId: string,
    pdfUrl: string,
    podId: string,
    linkEditId: string | null,
    storeId: string,
    thumbnailUrl: string
  }) => {
  const { pdfMetaStore, opStore, sessionStore, uiStore, podStore } = useStore()
  const { session } = sessionStore
  
  const [loadingError, setLoadingError] = useState(false)
  const [hasVariableSize, setHasVariableSize] = useState(false)
  const [pageHeights, setPageHeights] = useState<number[]>([])
  const [pageWidths, setPageWidths] = useState<number[]>([])
  const [numPages, setNumPages] = useState(0)
  const scale = pdfMetaStore.getScale(storeId)
  const [currentScale, setCurrentScale] = useState(scale)
  const [ selectedInteraction, setSelectedInteraction ] =useState<string|null>(null)

  useEffect(() => {
    // update the viewport of the pdf based on the new scaling
    function handleScaleChange (prevScale: number, nextScale: number) {
      // check if scaleSelect is a number
      if(isNaN(nextScale) === false) {
        const heights = pageHeights.map(height => (height/prevScale)*nextScale)
        const widths = pageWidths.map(width => (width/prevScale)*nextScale)
        // update component state
        setPageHeights(heights)
        setPageWidths(widths)
        setCurrentScale(nextScale)
        // update store
        pdfMetaStore.setPageHeights(storeId, heights)
        pdfMetaStore.setPageWidths(storeId, widths)
      }
    }
    // recalculate page sizes each time the scaling changes
    if(scale && scale !== currentScale) handleScaleChange(currentScale, scale)
  });



  useEffect(() => {
    // on mount: initialize pdfMeta in store
    if(pdfMetaStore.isInitialized(storeId) === false) {
      pdfMetaStore.initPdfMeta(storeId, pdfUrl, thumbnailUrl)
    }
    // on unmount: reset loading status
    return () => {
      pdfMetaStore.setLoaded(storeId, false)
    }
  }, [pdfMetaStore, storeId, pdfUrl, thumbnailUrl])



  // set the parameters of the pdf after loading and calculate viewport
  async function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
    if(pdf && pdf.numPages) {
      calculateViewport(pdf)
    }
  }

  const handleMouseUp = () => {
    if (window) {
      const sel = window.getSelection()
      // get position of pdf viewer component
      const pdfViewer = document.getElementById(`${storeId}-pdf-viewer`)?.getBoundingClientRect()
      // bottom and top page position of the viewed pdf section
      const pagePos = calculatePagePosition()

      if (sel && pdfViewer && pagePos) {
        // text inside selection
        const text = sel.toString()
        // get rects of selection
        const range   = sel.getRangeAt(0)
        const rectsArray   = range.getClientRects()

        // for each rectangle within a selection
        if(rectsArray.length) {
          const rects = []
          for(let i=0; i<rectsArray.length; i++) {
            const rect = rectsArray[i]
            // y position within the pdf viewer component
            const yViewerPosition = (rect.y - pdfViewer.y)
            // y position in relation to the height of the pdf viewer component
            const yRelativePosition = yViewerPosition / pdfViewer.height
            // calculate at which position the selection was made within the lower and upper page position of the considered pdf section
            const yPagePos = pagePos[0] + ((pagePos[1] - pagePos[0]) * yRelativePosition)
            // page on which the selection was made
            const pageNumber = Math.floor(yPagePos)

            // determine selected pdf page, calculate coordinates within the pdf page
            const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[0]?.children[0].getBoundingClientRect()
            if(pageElement && pageElement.x && pageElement.y && pageElement.height && pageElement.width) {
              // sort out incorrect selections that include the entire pdf page and beyond.
              if(rect.width < pageElement.width && rect.height < pageElement.height) {
                const x = rect.x - pageElement.x
                const y = rect.y - pageElement.y

                // normalize with the scaling of the pdf
                // parse overlay coordinates to three decimal places
                // start with page number 1
                const position: RectModel = {
                  x: parseToFixed(x / currentScale),
                  y: parseToFixed(y / currentScale),
                  h: parseToFixed(rect.height / currentScale),
                  w: parseToFixed(rect.width / currentScale),
                  p: (pageNumber+1)
                }
                // do not consider zero heights or widths or NaN pageNumber
                if(position.h > 1 && position.w > 1 && !Number.isNaN(position.p)) {
                  rects.push(position)
                }
              }
            }
          }

          // remove text selection from mouse
          sel.removeAllRanges()

          // get interaction type selected in the sidebar
          const interactionType = uiStore.getSelectedInteraction()
          // get overlay color selected in the sidebar
          const color = uiStore.getOpColor(interactionType)
          // optimizes the number of rectangles
          const optimizedRects = optimizeRects(rects)

          if(optimizedRects.length) {
            const anchor: interactionAnchor = {
              nodeId: pdfId,
              relText: text,
              rects: optimizedRects,
            }
            // if linkEdit: process selection in EditLink View
            if(linkEditId) {
              podStore.setLinkOverlay(storeId, anchor)
            }
            // if add annotation: save selected rects as operation
            else if(interactionType === "addAnnotation") {
              opStore.doOp({
                op: interactionType,
                usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
                podId: podId,
                data: {
                  interactionId: sessionStore.createUuid(),
                  userId: session.user.uid,
                  userName: session.user.name,
                  anchor: anchor,
                  style: { color: color },
                  label: "",
                  tCreated: Math.floor(Date.now()/1000),
                  tModified: Math.floor(Date.now()/1000),
                  lastModUuid: sessionStore.createUuid()
                }
              })
            }
            // if add link: create a link object with selected text and rects
            else if(interactionType === "addLink") {
              makeLink(anchor)
            }
            // else process selection in modal
            else {
              uiStore.setSelectedAnchor(anchor)
              uiStore.openInteractionModal()
            }
          }
        }
      }
    }
  }

  const makeLink = (anchor: interactionAnchor) => {
    if (podId) {
      const srcInteractionId = sessionStore.createUuid()
      const dstInteractionId = sessionStore.createUuid()
      const linkId = sessionStore.createUuid()
      const nodeId = pdfId
      opStore.doOp({
        op: 'addLink',
        usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
        podId: podId,
        data: {
            coid: null,
            interactionId: srcInteractionId,
            userId: session.user.uid,
            userName: session.user.userName,
            linkId: linkId,
            linkType: 'related',
            which: 'src',
            other: dstInteractionId,
            anchor: anchor,
            style: {},
            label: '',
            tCreated: Math.floor(Date.now()/1000),
            tModified: Math.floor(Date.now()/1000),
        }
      })

      opStore.doOp({
        op: 'addLink',
        podId: podId,
        usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
        data: {
            coid: null,
            interactionId: dstInteractionId,
            userId: session.user.uid,
            userName: session.user.userName,
            linkId: linkId,
            linkType: 'related',
            which: 'dst',
            other: srcInteractionId,
            anchor: {
              nodeId: nodeId,
              relText: '',
              rects: [],
            },
            style: {},
            label: '',
            tCreated: Math.floor(Date.now()/1000),
            tModified: Math.floor(Date.now()/1000),
        }
      })

      // navigate to the link
      const editLinkPath = `/pod/${podId}/pdf/${nodeId}/editLink/${srcInteractionId}`
      // TODO: navigate to edit link path
    }
  }

  // save the width and height of each pdf page and test if all pages are the same size
  const calculateViewport = async (pdf: PDFDocumentProxy) => {
    if(pdf && scale) {
      let heights: number[] = []
      let widths: number[] = []
      // get height and width from each page
      for(let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i)
        let viewport = page.getViewport({scale: scale})
        // compare each page with the first to see if all pages are the same size
        if(!hasVariableSize && Array.isArray(heights) && heights.length && heights[0] !== viewport.height) {
          setHasVariableSize(true)
        }
        heights.push(viewport.height)
        widths.push(viewport.width)
      }
      // update component state
      // prevent unnecessary rendering by setting all component variables at the same time
      setNumPages(pdf.numPages)
      setPageHeights(heights)
      setPageWidths(widths)
      // update store
      pdfMetaStore.setNumPages(storeId, pdf.numPages)
      pdfMetaStore.setPageHeights(storeId, heights)
      pdfMetaStore.setPageWidths(storeId, widths)
      pdfMetaStore.setLoaded(storeId, true)
    }
  }

  const getPageHeight = (pageIndex: number) => {
    if(Array.isArray(pageHeights) && pageHeights.length) return pageHeights[pageIndex]
    else {
      console.error("empty height array")
      return 0
    }
  }

  /** loads the pdf page selected by react-window:
   * when scrolling or loading, an image of the page is displayed to increase performance
   * if the page has not been loaded yet, show only one image without loading other pages in the background,
   * so that not too many elements have to be loaded at once when scrolling fast.
   * memo: prevent unnecessary re-rendering of pages when properties of the parent component are changed
   */
  const Segment = memo( ({ index, isScrolling, style }: {index: number, isScrolling: boolean, style: CSSProperties}) => {
    // prevent elements from loading before the page count of the pdf has been loaded
    if(pdfMetaStore.getLoaded(storeId) === false) return null
    // reference to pdf page, if page has not been created yet it is null
    const page = document.getElementById(`${storeId}-page-${index}`)
    
    if(isScrolling && page === null) {
      return (
        <div style={style}>
          <PageStyle>
            <img
              alt={`pdf page ${index+1}`}
              height={pageHeights?.length ? pageHeights[index] : "100%"}
              src={thumbnailUrl+"/"+(index+1)}
              width={pageWidths?.length ? pageWidths[index] : "100%"}
            />
          </PageStyle>
        </div>
      )
    }
    else return (
      <>
        <div id={`${storeId}-preview-image-${index}`} style={style}>
          <PageStyle>
            <img
              alt={`pdf page ${index+1}`}
              height={pageHeights?.length ? pageHeights[index] : "100%"}
              src={thumbnailUrl+"/"+(index+1)}
              width={pageWidths?.length ? pageWidths[index] : "100%"}
            />
          </PageStyle>
        </div>
        <div id={`${storeId}-page-${index}`} className='hidden' onMouseUp={handleMouseUp} style={style}>
          <PageStyle>
            <Page
              onRenderSuccess={() => pageRendered(index)}
              pageNumber={index+1}
              scale={scale}
            />
          </PageStyle>
          <Overlay
            linkEditId={linkEditId}
            pageId={`${storeId}-page-${index}`}
            pdfId={pdfId}
            pageNumber={index+1}
            scale={currentScale}
          />
        </div>
      </>
    )
  })

  const pageRendered = (index: number) => {
    const page = document.getElementById(`${storeId}-page-${index}`)
    const previewImage = document.getElementById(`${storeId}-preview-image-${index}`)
    if(page && previewImage) {
      page.classList.remove("hidden")
      previewImage.classList.add("hidden")
    }
  }

  const updatePagePosition = () => {
    const pagePos = calculatePagePosition()
    if(pagePos) {
      // update scroll position from page
      pdfMetaStore.setPagePos(storeId, pagePos)
    }
  }

   /* pagePos format:
   *  [page number of the upper edge of the page, page number of the lower edge of the side]
   */
  const calculatePagePosition = () => {
    if(pageListRef && pageListRef.current && pageListRef.current.state && pageListRef.current.props){
      // get the current scroll offset from the list of pdf pages
      let scrollOffset = pageListRef.current.state.scrollOffset
      // get height of pdf view window
      const listHeight = pageListRef.current.props.height
      // upper edge of the field of view
      let pagePosY0 = 0
      // lower edge of the field of view
      let pagePosY1 = 0

      // if not all pdf pages have the same size, the scroll distance must be subtracted page by page
      // the position within the corresponding page can be calculated from the remaining height
      if(hasVariableSize) {
        let num = 0
        for(const height of pageHeights) {
          scrollOffset -= height
          if(scrollOffset < 0) {
            const rest = 1-(Math.abs(scrollOffset)/height)
            pagePosY0 = num+rest
            break
          }
          num++
        }
        if(pageHeights.length >= num)
        pagePosY1 = pagePosY0 + listHeight/pageHeights[num]
      }
      else {
        const pageHeight = pageHeights[0]
        pagePosY0 = scrollOffset/pageHeight
        pagePosY1 = pagePosY0 + listHeight/pageHeight
      }
      // round to two decimal places
      pagePosY0 = Math.round(pagePosY0*100)/100
      pagePosY1 = Math.round(pagePosY1*100)/100
      return [pagePosY0, pagePosY1]
    }
  }

  // each time the page is rendered, update the reference
  const handleRefChange = (ref: any) => {
    pageListRef.current = ref
    const pagePos = pdfMetaStore.getPagePos(storeId)
    let pagePosTop = 0
    if(pagePos) pagePosTop = pagePos[0]
    // pdf with different page sizes support integer numbers only
    if(hasVariableSize) pagePosTop = Math.floor(pagePosTop)
    // scoll to last postion in the document
    if(ref && pagePosTop) ref.scrollToItem(pagePosTop, "start")
  }

  const SamePageSize = (props: any) => (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemCount={numPages}
          itemSize={Array.isArray(pageHeights) ? pageHeights[0] : 0}
          onScroll={updatePagePosition}
          overscanCount={2}
          ref={handleRefChange}
          useIsScrolling {...props}
          width={width}
        >
          {Segment}
        </FixedSizeList>
      )}
    </AutoSizer>
  )

  const VariablePageSize = (props: any) => (
    <AutoSizer>
      {({ height, width }) => (
        <VariableSizeList
          estimatedItemSize={height}
          height={height}
          itemCount={numPages}
          itemSize={getPageHeight}
          onScroll={updatePagePosition}
          overscanCount={2}
          ref={handleRefChange}
          useIsScrolling {...props}
          width={width}
        >
          {Segment}
        </VariableSizeList>
      )}
    </AutoSizer>
  )

  const SegmentContainer = () => {
    if(hasVariableSize) return <VariablePageSize />
    else return <SamePageSize />
  }


  // if pdf could not be loaded: show error dialog
  if(loadingError) return <Error />
  return (
    <div id={`${storeId}-pdf-viewer`} style={{display: "grid"}}>
      <Document
          className={"whiteSmoke"}
          file={pdfUrl}
          loading={<LoadingPdf />}
          onLoadError={(error) => {
            setLoadingError(true)
            console.warn("onLoadError:",error)
          }}
          // onLoadProgress={ (e) => { console.log('documentload', e);  } }
          onLoadSuccess={onDocumentLoadSuccess}
          onSourceError={(error) => {
            setLoadingError(true)
            console.log('Error while retrieving document source!', error.message)
          }}
          options={{
            cMapPacked: true,
            httpHeaders: {"X-SHRIMP-ID": sessionStore.session.sessionId},
            renderAnnotationLayer: false,
          }}
          renderMode={'canvas'}
          >
            {pageHeights?.length && (
                <SegmentContainer />
            )}
      </Document>
    </div>
  )
}

export default observer(Pdf)


// style components -------------------------------------------------

const PageStyle = styled('div')({
  backgroundColor: "whitesmoke",
  display: "grid",
  justifyItems: "center",
  padding: "15px 0",
  borderLeft: "1px solid lightgrey"
})

function parseToFixed(number: number) {
  return parseFloat(number.toFixed(3))
}

// adopted from shrimp-pods
function optimizeRects(rect: any, verbose = false) {

  const nrect = rect.map((r: any) => ({
    x1: r.x,
    y1: r.y,
    x2: r.x + r.w,
    y2: r.y + r.h,
    p: r.p
  }))

  if (verbose) console.log(nrect)

  const intersect = (rect1: any, rect2: any) => {
    return Math.max(0, Math.min(rect1.x2, rect2.x2) - Math.max(rect1.x1, rect2.x1)) * Math.max(0, Math.min(rect1.y2, rect2.y2) - Math.max(rect1.y1, rect2.y1))
  }

  const union = (rect1: any, rect2: any) => {
    return ((rect1.x2 - rect1.x1) * (rect1.y2 - rect1.y1)) + ((rect2.x2 - rect2.x1) * (rect2.y2 - rect2.y1)) - intersect(rect1, rect2)
  }

  const calc = (i0: any, i1: any) => {
    const nrect1 = nrect[i0]
    const nrect2 = nrect[i1]
    const si = intersect(nrect1, nrect2 )
    const su = union(nrect1, nrect2)
    if (verbose) console.log(si, su, 100 * si/su )
    return 100 * si/su
  }

  for(var i0=0; i0<nrect.length; i0++) {
    if (nrect[i0] === null) continue
    for(var i1=i0+1; i1<nrect.length; i1++) {
      if (nrect[i1] === null) continue
      const ratio = calc(i0, i1)
      if (verbose) console.log(`Compare ${i0} to ${i1}: `, ratio)
      if (ratio > 70) {
        if (verbose) console.log('merge')
        nrect[i0] = {
          x1: Math.min(nrect[i0].x1, nrect[i1].x1),
          y1: Math.min(nrect[i0].y1, nrect[i1].y1),
          x2: Math.max(nrect[i0].x2, nrect[i1].x2),
          y2: Math.max(nrect[i0].y2, nrect[i1].y2),
          p: nrect[i0].p
        }
        nrect[i1] = null
      }
    }
  }

  return nrect.filter((n: any) => n !== null).map((r: any) => ({
    x: r.x1,
    y: r.y1,
    w: r.x2 - r.x1,
    h: r.y2 - r.y1,
    p: r.p
  }))
}